import { useEffect, useMemo } from 'react';
import { styled } from '@mui/material';
import MenuNav from '../MenuNav/MenuNav';
import LoadingMenu from '../LoadingMenu/LoadingMenu';
import ErrorMenu from '../ErrorMenu/ErrorMenu';
import { useMenu } from '../../../../hooks/useMenu';
import MenuCategory from '../MenuCategory/MenuCategory';
import { StoreInterface } from '../../../../types/stores';
import { useCategoryScroll } from '../../../../hooks/useCategoryScroll';
import { MenuItem } from '../../types';
import ToggleOrderType from '../ToggleOrderType/ToggleOrderType';
import { useSearchParams } from 'react-router-dom';
import { useCartV2 } from '../../../../hooks/useCartV2';
import { MenuOptions, PricingOptions } from '../../../Cart/types';
import { SessionKeys } from '../../../../utils/Session/Session';

interface MenuDetailProps {
  store: StoreInterface;
}

const MenuDetailContainer = styled('div')(({ theme }) => ({
  paddingLeft: theme.spacing(4),
  paddingRight: theme.spacing(4),
  paddingBottom: theme.spacing(16)
}));

const MenuLoadingContainer = styled('div')(({ theme }) => ({
  paddingTop: theme.spacing(4),
  paddingLeft: theme.spacing(4),
  paddingRight: theme.spacing(4),
  display: 'flex',
  justifyContent: 'center'
}));

const MenuDetail = ({ store }: MenuDetailProps) => {
  const { priceToDisplay, setPriceToDisplay, showToggle, setShowToggle } =
    useCartV2();
  const [searchParam] = useSearchParams();
  const { data, isFetching, isError, refetch, isRefetching, isRefetchError } =
    useMenu(store.id);

  const setPriceFromUrl = (param: string) => {
    const display = param === 'takeaway'
      ? PricingOptions.TAKEOUT
      : PricingOptions.DINEIN;
    setPriceToDisplay(display);
  }

  useEffect(() => {
    if (data) {
      const orderParam = searchParam.get('ordering');
      const isBoth = data.menuType === MenuOptions.BOTH;

      if (!isBoth) {
        setShowToggle(false);
        const display =
          data.menuType === MenuOptions.TAKEOUT
            ? PricingOptions.TAKEOUT
            : PricingOptions.DINEIN;
        setPriceToDisplay(display);
      }

      if (isBoth && (orderParam === 'takeaway' || orderParam === 'dinein')) {
        setShowToggle(false);
        setPriceFromUrl(orderParam);
      }
    }
  }, [data]);

  useEffect(() => {
    sessionStorage.setItem(SessionKeys.ORDER_TYPE, priceToDisplay);
  }, [priceToDisplay]);

  const availableCategories: { [key: string]: MenuItem[] } = useMemo(
    () =>
      data
        ? data.menuItems.reduce((acc, cur) => {
            if (!acc[cur.category]) {
              acc[cur.category] = [cur];
            } else {
              acc[cur.category].push(cur);
            }
            return acc;
          }, {})
        : {},
    [data]
  );

  const { tabIndex, setAutoScroll, setManualScroll } =
    useCategoryScroll(availableCategories);

  if (isError || isRefetchError) {
    return (
      <MenuLoadingContainer>
        <ErrorMenu onClick={refetch} />
      </MenuLoadingContainer>
    );
  }

  if (isFetching || isRefetching) {
    return (
      <MenuLoadingContainer>
        <LoadingMenu />
      </MenuLoadingContainer>
    );
  }

  return (
    <MenuDetailContainer data-testid="menu-detail">
      {showToggle && <ToggleOrderType />}
      <MenuNav
        tabIndex={tabIndex}
        setTab={(tracker, scrollWait) => setAutoScroll({ tracker, scrollWait })}
      />
      {Object.entries(availableCategories).map((category, index) => (
        <MenuCategory
          key={index}
          content={category[1]}
          category={category[0]}
          screenTracker={(tracker) => setManualScroll({ tracker })}
          store={store}
          priceToDisplay={priceToDisplay}
        />
      ))}
    </MenuDetailContainer>
  );
};

export default MenuDetail;
