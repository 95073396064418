import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import Button, { ButtonProps } from '@mui/material/Button';
import { useCartV2 } from '../../../hooks/useCartV2';
import { BotStatus } from '../../Cart/types';

interface PlaceOrderProps {
  handlePress: () => void;
  disabled: boolean;
}

const PlaceOrderContainer = styled('div')(({ theme }) => ({
  backgroundColor: theme.colors.base.white,
  borderTop: `1px solid ${theme.colors.gray[300]}`,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'fixed',
  bottom: 0,
  height: theme.spacing(22),
  padding: theme.spacing(4),
  width: '100%',
  zIndex: 10000,
  maxWidth: '1024px'
}));

const PlaceOrderButton = styled(Button)<ButtonProps>(({ theme }) => ({
  borderRadius: theme.spacing(1),
  backgroundColor: theme.colors.base.black,
  textTransform: 'none',
  '&:hover': {
    backgroundColor: theme.colors.base.black
  },
  boxShadow: 'none',
  color: theme.colors.base.white,
  fontSize: theme.spacing(4),
  height: theme.spacing(12),
  marginBottom: theme.spacing(2),
  padding: 0,
  width: '100%'
}));

const PlaceOrder = ({ handlePress, disabled }: PlaceOrderProps) => {
  const { t } = useTranslation();
  const { isBot } = useCartV2();

  return (
    <PlaceOrderContainer>
      <PlaceOrderButton
        variant="contained"
        disableRipple
        onClick={handlePress}
        data-testid="checkout-continue-to-payment"
        disabled={isBot === BotStatus.IS_BOT || disabled}
      >
        {t('restaurant.checkout.placeOrder')}
      </PlaceOrderButton>
    </PlaceOrderContainer>
  );
};

export default PlaceOrder;
