import { appConfig } from '../../config/appConfig';
import { PaymentGatewayType } from '../../types/fetch';

// Determine whether to pass true for x-test header
// This is needed to test google pay in local or dev environments with Stripe
// When x-test is true, BE pulls a fake partner with test credentials to process a google payment
// When x-test is false, BE uses a live partner (in test mode) to process payments
// If payment gateway is stripe, and we are in local or dev environments
// and we are testing chrome and google pay -- pass true
// if we are in safari or using another payment gateway or not in local/dev -- pass false
const getXTest = (paymentGateway: PaymentGatewayType) => {
  if (
    !window.ApplePaySession &&
    paymentGateway === PaymentGatewayType.Stripe &&
    (appConfig.environment.toUpperCase() === 'LOCAL' ||
      appConfig.environment.toUpperCase() === 'DEV')
  ) {
    return true;
  }
  return false;
};

export default getXTest;
