import { useState, JSX } from 'react';
import { StoreInterface } from '../../../../types/stores';
import { useCartV2 } from '../../../../hooks/useCartV2';
import { useCalculate } from '../../../../hooks/useCalculate';
import { styled } from '@mui/material/styles';
import Snackbar, { SnackbarProps } from '@mui/material/Snackbar';
import Slide, { SlideProps } from '@mui/material/Slide/Slide';
import CartButton from '../CartButton/CartButton';
import { BotStatus } from '../../../Cart/types';

interface ViewCartBannerProps {
  store: StoreInterface;
}

const SiteSnackbar = styled(Snackbar)<SnackbarProps>(({ theme }) => ({
  color: theme.colors.partner.button.primary.font,
  height: theme.spacing(17),
  margin: 'auto',
  maxWidth: theme.spacing(250),
  backgroundColor: '#FFFFFF',
  [theme.breakpoints.up('sm')]: {
    left: theme.spacing(5),
    right: theme.spacing(4),
    bottom: theme.spacing(0)
  }
}));

const CartButtonStyled = styled('div')<SnackbarProps>(({ theme }) => ({
  marginTop: theme.spacing(-2),
  width: '99.5%',
  height: '100%',
  cursor: 'pointer'
}));

const SlideTransition = (props: JSX.IntrinsicAttributes & SlideProps) => {
  return <Slide {...props} direction="up" />;
};

const ViewCartBanner = ({ store }: ViewCartBannerProps) => {
  const { getQuantity, isBot } = useCartV2();
  const cartQuantity = getQuantity();
  const [openCartBanner] = useState(!!cartQuantity);
  const { cartCalculations, errorDialog } = useCalculate(store);

  if (!cartQuantity || !cartCalculations) {
    return null;
  }

  const button = <CartButton store={store} />;

  return (
    <>
      <SiteSnackbar
        data-testid="view-cart-banner"
        open={openCartBanner && isBot !== BotStatus.IS_BOT}
        TransitionComponent={SlideTransition}
        transitionDuration={1000}
        TransitionProps={{
          enter: false
        }}
      >
        <CartButtonStyled>{button}</CartButtonStyled>
      </SiteSnackbar>
      {errorDialog}
    </>
  );
};

export default ViewCartBanner;
