import { appConfig } from '../../../config/appConfig';
import { useEffect, useMemo, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { useSiteConfig } from '../../../hooks/useSiteConfig';
import getPaymentAmount from '../../../utils/Payments/getPaymentAmount';
import { GatewayProp } from '../types/GatewayProp';
import { Elements } from '@stripe/react-stripe-js';
import fetchClientSecret from '../../../utils/Payments/Stripe/fetchClientSecret';
import updatePaymentIntent from '../../../utils/Payments/Stripe/updatePaymentIntent';
import StripePaymentForm from './StripePaymentForm';
import { GatewayError } from '../GatewayError';
import { PaymentFormResponse } from '../types/PaymentFormResponse';
import { styled } from '@mui/material/styles';
import getXTest from '../../../utils/Payments/getXTest';
import { PaymentGatewayType, PaymentMethodType } from '../types/GatewayResponse';

const StyledPaymentFormContainer = styled('div')(({ theme }) => ({
  padding: theme.spacing(3)
}));

const Stripe = (props: GatewayProp) => {
  const { partnerConfig, paymentGateway } = useSiteConfig();
  const [clientSecret, setClientSecret] = useState<string | undefined>(
    window.sessionStorage.getItem('client_secret') ?? undefined
  );

  // Switch to test account for Google Pay on Dev
  const getStripeAccountId = (stripeAccountId: string) => {
    if (
      !window.ApplePaySession &&
      (appConfig.environment.toUpperCase() === 'LOCAL' ||
        appConfig.environment.toUpperCase() === 'DEV')
    ) {
      return appConfig.stripeTestAccountId;
    }
    return stripeAccountId;
  };

  const stripeAmount = getPaymentAmount(
    props.cartCalculations.data.summary.total
  );

  const stripePromise = useMemo(
    () =>
      loadStripe(
        paymentGateway?.publicKey,
        !paymentGateway?.accountId
          ? undefined
          : { stripeAccount: getStripeAccountId(paymentGateway?.accountId) }
      ),
    [paymentGateway.accountId, paymentGateway.publicKey]
  );

  const onSuccess = (formResponse: PaymentFormResponse) => {
    window.sessionStorage.removeItem('client_secret');

    if (!formResponse.paymentIntent.paymentIntent?.amount) {
      props.onError(
        new GatewayError('unknown', PaymentMethodType.Card, null, null, {
          details: 'No amount returned'
        })
      );
      return;
    }

    props.onSuccess({
      type: formResponse.paymentType,
      amount: formResponse.paymentIntent.paymentIntent?.amount / 100,
      confirmationId: formResponse.paymentIntent.paymentIntent?.id,
      gateway: PaymentGatewayType.stripe,
      paymentMethod: PaymentMethodType.Card
    });
  };

  useEffect(() => {
    if (!clientSecret) {
      fetchClientSecret(
        stripeAmount,
        partnerConfig.partnerId,
        getXTest(paymentGateway.paymentGateway)
      )
        .then((clientSecretReturn) => {
          setClientSecret(clientSecretReturn);
          window.sessionStorage.setItem('client_secret', clientSecretReturn);
        })
        .catch(props.onError);

      return;
    }
    updatePaymentIntent(
      clientSecret,
      stripeAmount,
      partnerConfig.partnerId,
      getXTest(paymentGateway.paymentGateway)
    ).catch(() => {
      window.sessionStorage.removeItem('client_secret');
      setClientSecret(undefined);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Return null until clientSecret is retrieved
  if (!clientSecret) {
    return null;
  }

  // Show form
  return (
    <StyledPaymentFormContainer>
      <Elements stripe={stripePromise} options={{ clientSecret }}>
        <StripePaymentForm
          onSubmit={props.onSubmit}
          onError={props.onError}
          onSuccess={onSuccess}
        />
      </Elements>
    </StyledPaymentFormContainer>
  );
};

export default Stripe;
