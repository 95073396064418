import { backendConfig } from '../../../config/appConfig';
import axios from 'axios';

const fetchClientSecret = async (
  amount: number,
  siteId: string,
  xTest?: boolean
) => {
  const preAuthPaymentUrl = `${backendConfig.apiBaseUrl}/eat/payment/stripe/partner/${siteId}/preAuth`;

  const requestBody = JSON.stringify({
    amount: amount
  });

  const axiosConfig = {
    method: 'POST',
    url: preAuthPaymentUrl,
    headers: {
      'Content-Type': 'application/json',
      'x-api-key': backendConfig.apiKey,
      'x-test': xTest ? xTest : false
    },
    data: requestBody
  };
  const response = await axios(axiosConfig);
  const clientSecret: string = response.data.client_secret;

  return clientSecret;
};

export default fetchClientSecret;
