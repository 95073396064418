import AppRoute from '../../routing/AppRoute';
import PickupTimeChange from '../PickupTimeChange/PickupTimeChange';
import { useEffect, useState } from 'react';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { useCartV2 } from '../../hooks/useCartV2';
import { useStore } from '../../hooks/useStore';
import { useSiteConfig } from '../../hooks/useSiteConfig';
import { usePickupTimes } from '../../hooks/usePickupTimes';
import NoTimesAvailableDialog from '../NoTimesAvailableDialog/NoTimesAvailableDialog';
import PickNewTimeDialog from '../PickNewTimeDialog/PickNewTimeDialog';
import { isStoreOpen } from '../../utils/Store/isStoreOpen';
import { format } from 'date-fns';

interface PickupTimeAlertProps {
  showDialog?: boolean;
}

const PickupTimeAlert = (props: PickupTimeAlertProps) => {
  const [showNoTimesAvailable, setShowNoTimesAvailable] = useState(false);
  const [showPickNewTime, setShowPickNewTime] = useState(false);
  const [timeChange, setTimeChange] = useState(false);
  const { partnerConfig: siteConfig } = useSiteConfig();
  const { storeId: shortId } = useParams();
  const { data: store } = useStore(siteConfig.partnerId, shortId ?? '');
  const { data: times, isFetched } = usePickupTimes(store?.id ?? '');

  const navigate = useNavigate();
  const { pickupTime, setPickupTime, clearCart, setShowToggle, showToggle } = useCartV2();
  const sessionKey = 'hasSeenAlert' + (shortId ?? '');
  const hasSeenAlert = JSON.parse(
    sessionStorage.getItem(sessionKey) ?? 'false'
  );

  useEffect(() => {
    // Times not ready yet
    if (!isFetched || !store) {
      return;
    }

    const today = format(new Date(), 'EEEE');
    if (!isStoreOpen(store.days[today], siteConfig.timeZone)) {
      return;
    }

    if (!times || times.length <= 0) {
      setTimeChange(false);
      setShowNoTimesAvailable(true);
      setShowPickNewTime(false);
      return;
    }

    // Check for asap
    if (times[0] && times[0].isAsap && !pickupTime) {
      return;
    }

    // Update asap when no longer available
    if (!pickupTime) {
      setPickupTime(times[0].date);
      setTimeChange(true);
      setShowNoTimesAvailable(false);
      setShowPickNewTime(false);
      return;
    }

    // Look for pickup time in times list, and
    // get the next time available
    let found = false;
    let nextIndex: number | null = null;
    times.forEach((value, index) => {
      if (found || typeof nextIndex === 'number') {
        /* Defensive coding.  Should never hit this */
        /* istanbul ignore next */
        return;
      }

      /* Defensive coding.  Should never hit this */
      /* istanbul ignore next */
      if (!pickupTime && value.isAsap) {
        found = true;
        return;
      }

      if (
        pickupTime &&
        value.date.getTime() === new Date(pickupTime).getTime()
      ) {
        found = true;
        return;
      }

      if (pickupTime && value.date > new Date(pickupTime)) {
        nextIndex = index;
      }
    });

    // Time still available exit out
    if (found) {
      return;
    }

    // Update time to asap.
    if (!nextIndex && times[0] && times[0].isAsap) {
      setPickupTime(null);
      setTimeChange(false);
      setShowNoTimesAvailable(false);
      setShowPickNewTime(true);
      return;
    }

    /* Defensive coding.  Should never hit this */
    /* istanbul ignore next */
    if (nextIndex && times[nextIndex] && times[nextIndex].isAsap) {
      setPickupTime(null);
      setTimeChange(true);
      setShowNoTimesAvailable(false);
      setShowPickNewTime(false);
      return;
    }

    // Update time to next available
    if (typeof nextIndex === 'number') {
      setPickupTime(times[nextIndex].date);
      setTimeChange(true);
      setShowNoTimesAvailable(false);
      setShowPickNewTime(false);
      return;
    }

    // Update time to first available (past)
    if (times && times[0]) {
      setPickupTime(times[0].date);
      setTimeChange(false);
      setShowNoTimesAvailable(false);
      setShowPickNewTime(true);
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [times, pickupTime, store, siteConfig.timeZone]);

  const handleCloseNoTimesAvailable = () => {
    sessionStorage.setItem(sessionKey, JSON.stringify(true));
    setShowNoTimesAvailable(false);
    clearCart();
    setShowToggle(showToggle);
    navigate(
      generatePath(AppRoute.MENU, {
        storeId: String(shortId)
      })
    );
  };

  if (timeChange) {
    return (
      <PickupTimeChange
        showDialog={props.showDialog}
        open={timeChange}
        close={() => setTimeChange(false)}
      />
    );
  }

  if (showNoTimesAvailable && !hasSeenAlert) {
    // checkout content time alerts
    return (
      <NoTimesAvailableDialog
        open={showNoTimesAvailable}
        close={handleCloseNoTimesAvailable}
      />
    );
  }

  if (showPickNewTime) {
    return (
      <PickNewTimeDialog
        open={showPickNewTime}
        close={() => setShowPickNewTime(false)}
      />
    );
  }

  return null;
};

export default PickupTimeAlert;
