const extractNameFromDataString = (dataString: string) => {
  if (dataString === '') {
    return '';
  }

  const dataObject = JSON.parse(dataString);
  const givenName = dataObject.givenName || '';
  const familyName = dataObject.familyName || '';

  if (!givenName && !familyName) {
    return '';
  }

  if (givenName && !familyName) {
    return givenName;
  }
  if (familyName && !givenName) {
    return familyName;
  }
  return `${givenName} ${familyName}`;
};

export default extractNameFromDataString;
