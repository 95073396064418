import { CartItem } from '../../features/Cart/types';
import { PostOrder } from '../../types/fetch';
import { OrderType } from '../../types/order';
import { UserInfo } from '../../types/userInfo';
import setCartModifiersForProcess from './setCartModsForProcess';

export default function createPostOrderObject(
  cartItems: CartItem[],
  pickupTime: Date | string | null,
  hasRoundUp: boolean,
  tip: number,
  userInfo: UserInfo,
  promoCode: string | null,
  menuId: number,
  orderType: OrderType,
  orderId: number | string | null
): PostOrder {
  const postOrderCart = cartItems.map((cartItem) => {
    return {
      productId: cartItem.productId,
      clientItemId: cartItem.productId.toString(),
      quantity: cartItem.quantity,
      itemNotes: cartItem.itemNotes,
      modifiers: setCartModifiersForProcess(cartItem.modifiers)
    };
  });

  const formatPickupTime = pickupTime
    ? new Date(pickupTime).toISOString()
    : null;

  const newPostOrder: PostOrder = {
    orderDate: formatPickupTime,
    cart: postOrderCart,
    hasRoundUp: hasRoundUp,
    tip: Number(tip),
    menuId: menuId,
    channel: 'mobile',
    orderType: orderType,
    location: userInfo.table ? userInfo.table : undefined,
    customer: {
      name: userInfo.name,
      phone: userInfo.phone
    },
    discount: promoCode ? promoCode : '',
    orderId: orderId ? Number(orderId) : null
  };

  return newPostOrder;
}
