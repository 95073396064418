import axios from 'axios';
import { backendConfig } from '../../../config/appConfig';
import { FREEDOM_PAY } from '../../../config/backendServicesEndpoints';

export interface Validate3DSPayload {
  eci: string;
  paResStatus: string;
  cavv: boolean;
  enrolled: string;
}

const validate3DS = async (payload: Validate3DSPayload) => {
  const url = `${FREEDOM_PAY}/3ds`;

  const requestBody = JSON.stringify(payload);

  const axiosConfig = {
    method: 'POST',
    url: url,
    headers: {
      'Content-Type': 'application/json',
      'x-api-key': backendConfig.apiKey
    },
    data: requestBody
  };
  try {
    const response = await axios(axiosConfig);
    return response.data;
  } catch (err) {
    throw err;
  }
};

export default validate3DS;
