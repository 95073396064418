import CircularProgress from '@mui/material/CircularProgress';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText, {
  DialogContentTextProps
} from '@mui/material/DialogContentText';
import { styled } from '@mui/material/styles';
import { ReactNode } from 'react';

interface LoadingDialogProps {
  open: boolean;
  content?: string;
  children?: ReactNode;
}

const Confirmation = styled(Dialog)<DialogProps>(({ theme }) => ({
  '.MuiDialog-paper': {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    width: '40px',
    height: '40px'
  }
}));

const ConfirmationText = styled(DialogContentText)<DialogContentTextProps>(
  ({ theme }) => ({
    fontSize: theme.spacing(3.5),
    paddingLeft: '40px'
  })
);

const LoadingSpinner = styled(CircularProgress)(({ theme }) => ({
  color: theme.colors.base.black,
  position: 'absolute',
  top: '0',
  bottom: '0',
  left: '0',
  right: '0',
  margin: 'auto'
}));

const LoadingDialog = ({ open, content, children }: LoadingDialogProps) => {
  return (
    <>
      <Confirmation open={open} data-testid="loading-dialog">
        <LoadingSpinner size={24} data-testid="menu-loading-spinner" />
        {content && (
          <DialogContent>
            <ConfirmationText data-testid="menu-loading-text">
              {content}
            </ConfirmationText>
          </DialogContent>
        )}

        {children && <DialogActions>{children}</DialogActions>}
      </Confirmation>
    </>
  );
};

export default LoadingDialog;
