import {
  PaymentElement,
  useElements,
  useStripe
} from '@stripe/react-stripe-js';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import {
  StripePaymentElementChangeEvent,
  StripePaymentElementOptions
} from '@stripe/stripe-js';
import { useAnalytics } from '../../../hooks/useAnalytics';
import { FormEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GatewayError } from '../GatewayError';
import { getErrorDialogText } from '../../../utils/Payments/Stripe/errors';
import { PaymentFormResponse } from '../types/PaymentFormResponse';
import { useCartV2 } from '../../../hooks/useCartV2';
import { BotStatus } from '../../Cart/types';
import { PaymentMethodType } from '../types/GatewayResponse';

const PaymentElementContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column'
}));

const PlaceOrder = styled(Button)(({ theme }) => ({
  color: theme.colors.partner.button.primary.font,
  fontSize: theme.spacing(4),
  fontWeight: 500,
  backgroundColor: theme.colors.partner.button.primary.background,
  '&:hover': {
    backgroundColor: theme.colors.partner.button.primary.background
  },
  width: '100%',
  height: theme.spacing(12),
  borderRadius: theme.spacing(2),
  marginTop: theme.spacing(6)
}));

const paymentElementOptions: StripePaymentElementOptions = {
  layout: {
    type: 'accordion'
  }
};

export interface StripePaymentFormProps {
  onSubmit: () => void;
  onSuccess: (formResponse: PaymentFormResponse) => void;
  onError: (error: GatewayError) => void;
}

const StripePaymentForm = (props: StripePaymentFormProps) => {
  const [paymentType, setPaymentType] = useState<string>('credit_card');
  const logEvent = useAnalytics();
  const { t } = useTranslation();
  const stripe = useStripe();
  const elements = useElements();
  const { isBot } = useCartV2();

  const handlePaymentElementChange = async (
    e: StripePaymentElementChangeEvent
  ) => {
    switch (e.value.type) {
      case 'google_pay': {
        if (paymentType !== 'google_pay') {
          setPaymentType('google_pay');
          logEvent('payment_selection', {
            payment_method: 'google_pay'
          });
        }
        return;
      }
      case 'apple_pay': {
        if (paymentType !== 'apple_pay') {
          setPaymentType('apple_pay');
          logEvent('payment_selection', {
            payment_method: 'apple_pay'
          });
        }
        return;
      }
      default: {
        if (paymentType !== 'credit_card') {
          setPaymentType('credit_card');
          logEvent('payment_selection', {
            payment_method: 'credit_card'
          });
        }
        return;
      }
    }
  };

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    props.onSubmit();

    if (!stripe || !elements) {
      return;
    }

    stripe
      .confirmPayment({
        elements,
        confirmParams: {
          return_url: `${window.location.href}`
        },
        redirect: 'if_required'
      })
      .then((paymentIntentResult) => {
        if (!paymentIntentResult.error) {
          props.onSuccess({
            paymentIntent: paymentIntentResult,
            paymentType: paymentType
          });
          return;
        }

        if (paymentIntentResult.error.type === 'validation_error') {
          props.onError(
            new GatewayError(
              'incomplete',
              PaymentMethodType.Card,
              null,
              null,
              paymentIntentResult
            )
          );
          return;
        }

        const errorResponse = getErrorDialogText(
          paymentIntentResult.error?.code ??
            paymentIntentResult.error?.decline_code
        );

        props.onError(
          new GatewayError(
            'declined',
            PaymentMethodType.Card,
            errorResponse.title,
            errorResponse.description,
            paymentIntentResult
          )
        );
      })
      .catch((error) => {
        props.onError(
          new GatewayError('unknown', PaymentMethodType.Card, null, null, error)
        );
      });
  };

  return (
    <PaymentElementContainer>
      <form
        id="payment-form"
        onSubmit={onSubmit}
        data-testid="credit-card-form"
      >
        <PaymentElement
          options={paymentElementOptions}
          onChange={handlePaymentElementChange}
        />
        <PlaceOrder
          id="submit"
          type="submit"
          data-testid="pay-button"
          disabled={isBot === BotStatus.IS_BOT}
        >
          {t('restaurant.checkout.placeOrder')}
        </PlaceOrder>
      </form>
    </PaymentElementContainer>
  );
};

export default StripePaymentForm;
