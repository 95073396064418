import { MenuItem, ModItem } from '../../features/Menu/types';
import { CartItem } from '../../features/Cart/types';

const setCartItemModifiers = (
  menuItem: MenuItem,
  modifiers: { [index: string]: ModItem }
) => {
  const newCartItemAttributes = menuItem.attributes.map((attr) => {
    return { name: attr };
  });

  const price = menuItem.dineInPrice;

  const newCartItem: CartItem = {
    productId: menuItem.productId,
    title: menuItem.name,
    description: menuItem.description,
    category: menuItem.category,
    retailPrice: price,
    modifiers: modifiers,
    quantity: 0,
    itemNotes: '',
    attributes: newCartItemAttributes,
    itemTotalDiscountPrice: 0,
    itemTotalPrice: 0,
    discountTotal: 0,
    tax: 0,
    gross: 0,
    unitRRPGross: 0
  };
  return newCartItem;
};

export default setCartItemModifiers;
