import { Button } from '@mui/material';
import { SxProps, Theme, styled } from '@mui/material/styles';
import { FC, useEffect, useRef, useState, KeyboardEvent } from 'react';
import ArrowDown from '@mui/icons-material/ExpandMore';
import FocusTrap from 'focus-trap-react';

export interface SelectProps {
  label: string;
  placeholder: string;
  value: string | number | null;
  options: (string | number)[];
  onChange?: (value: number | string | null) => void;
  sx?: SxProps<Theme>;
  disabled?: boolean;
}
const LabelStyled = styled('span')(({ theme }) => ({
  position: 'relative',
  paddingRight: theme.spacing(4.5),
  fontWeight: 'normal',
  textTransform: 'none'
}));

const ArrowDownStyled = styled(ArrowDown)(({ theme }) => ({
  position: 'absolute',
  left: theme.spacing(4),
  display: 'none'
}));

const SelectArrowDownStyled = styled(ArrowDown)(({ theme }) => ({
  height: '1.5rem',
  width: '1.5rem',
  paddingLeft: theme.spacing(1),
  position: 'absolute'
}));

const OverlayStyled = styled('div')(() => ({
  position: 'fixed',
  backgroundColor: 'transparent',
  left: 0,
  bottom: 0,
  width: '100vw',
  height: '0',
  zIndex: 9999,
  transition: 'height 1s ease',
  overscrollBehavior: 'contain',
  '&.shown': {
    height: '100vh'
  }
}));

const TransparentBackgroundStyled = styled('div')(() => ({
  position: 'fixed',
  left: 0,
  bottom: -52,
  width: '100vw',
  height: '100vh',
  backgroundColor: '#333',
  opacity: '0',
  overscrollBehavior: 'contain',
  transition: 'opacity 0.5s ease',
  zIndex: 9999,
  '&.shown': {
    opacity: '.5',
    height: '100vh'
  },
  '&.hide': {
    zIndex: -1
  }
}));

const SelectionBoxStyled = styled('div')(({ theme }) => ({
  position: 'absolute',
  left: 0,
  bottom: theme.spacing(-4),
  paddingBottom: theme.spacing(4),
  backgroundColor: '#FFF',
  width: '100vw',
  height: '0',
  transition: 'height 0.5s linear;',
  overscrollBehavior: 'contain',
  '&.shown': {
    height: '50vh',
    transition: 'height 0.3s linear;'
  },
  '&.full-page svg': {
    display: 'inline-block'
  },
  '&.full-page': {
    height: 'calc(100vh - 51px)'
  },
  '&.full-page .place-holder': {
    boxShadow: '0px 4px 10px #999'
  }
}));

const OptionRowStyled = styled('div')(({ theme }) => ({
  borderBottom: '1px solid',
  borderBottomColor: theme.colors.gray[300],
  color: '#000',
  fontSize: '1rem',
  paddingLeft: theme.spacing(4),
  paddingTop: theme.spacing(3),
  paddingBottom: theme.spacing(3),
  textAlign: 'left',
  '&:focus, &:hover': {
    fontWeight: 'bold',
    backgroundColor: theme.colors.gray[100]
  },
  '&.selected': {
    fontWeight: 'bold',
    backgroundColor: theme.colors.gray[100]
  },
  '&:last-child': {
    marginBottom: theme.spacing(8)
  }
}));

const PlaceHolderStyled = styled('div')(({ theme }) => ({
  position: 'relative',
  borderBottom: '1px solid',
  borderBottomColor: theme.colors.gray[300],
  color: '#000',
  fontSize: '1rem',
  textAlign: 'center',
  cursor: 'pointer',
  paddingTop: theme.spacing(4),
  paddingBottom: theme.spacing(4),
  zIndex: 1
}));

const SelectionsContainerStyled = styled('div')(() => ({
  position: 'relative',
  height: '100%',
  overflow: 'scroll'
}));

const ValueDisplayStyled = styled('span')(() => ({
  fontWeight: '500'
}));

const Selection: FC<SelectProps> = (props) => {
  const [open, setOpen] = useState(false);
  const boxRef = useRef<HTMLDivElement>(null);
  const overlayRef = useRef<HTMLDivElement>(null);
  const selectRef = useRef<HTMLDivElement>(null);
  const itemsRef = useRef<Array<HTMLDivElement | null>>([]);
  let y = 0;
  let mouseStarted = false;
  let mouseMoved = 0;
  let swipeStarted = false;
  let swipeInitial = 0;
  let swipeMoved = 0;

  const toggleDrawer = (openDrawer: boolean) => {
    if (mouseStarted) {
      return;
    }

    setOpen(openDrawer);

    let overlay: HTMLDivElement | null = null;

    if (overlayRef.current) {
      overlay = overlayRef.current;
    }

    // Prevent scroll while drawer is open
    if (openDrawer) {
      overlay?.classList.remove('hide');
      overlay?.classList.add('shown');
      return;
    }

    overlay?.classList.remove('shown');

    // Remove Z-index after animation
    setTimeout(() => {
      overlay?.classList.add('hide');
    }, 1000);
  };

  // @ts-expect-error Incorrect TS error reported
  const handleClick = (event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    event.preventDefault();

    /* istanbul ignore start */
    if (!event.target.textContent) {
      return;
    }
    /* istanbul ignore end */

    if (props.onChange) {
      props.onChange(event.currentTarget.textContent);
    }

    toggleDrawer(false);
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key !== 'Enter') {
      return;
    }

    event.stopPropagation();
    event.preventDefault();
    if (props.onChange && event.target) {
      props.onChange(event.currentTarget.textContent);
    }

    toggleDrawer(false);
  };

  const handleMouseUp = () => {
    if (!mouseStarted) {
      return;
    }

    mouseStarted = false;

    if (mouseMoved === 0) {
      return;
    }

    let box: HTMLDivElement | null = null;

    if (boxRef.current) {
      box = boxRef.current;
    }

    if (mouseMoved > 0) {
      box?.classList.remove('full-page');
      return;
    }

    box?.classList.add('full-page');
  };

  // @ts-expect-error Incorrect TS error reported
  const handleSwipeStart = (event: TouchEvent<HTMLDivElement>) => {
    window.document.body.style.overflow = 'hidden';
    swipeStarted = true;
    swipeInitial = event.touches[0].clientY;
  };

  // @ts-expect-error Incorrect TS error reported
  const handleSwipeMove = (event: TouchEvent<HTMLDivElement>) => {
    if (!swipeStarted) {
      return;
    }

    swipeMoved = event.touches[0].clientY - swipeInitial;
  };

  const handleSwipeEnd = () => {
    if (!swipeStarted) {
      return;
    }

    window.document.body.style.overflow = 'auto';

    swipeStarted = false;

    if (swipeMoved === 0) {
      return;
    }

    if (swipeMoved > 0) {
      // @ts-expect-error Testing
      boxRef.current.classList.remove('full-page');
      return;
    }

    // @ts-expect-error Testing
    boxRef.current.classList.add('full-page');
  };

  // @ts-expect-error Incorrect error from TS for Div
  const handleOnMouseDown = (event: MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    y = event.clientY;
    mouseStarted = true;
  };

  // @ts-expect-error Incorrect error from TS for Div
  const handleOnMouseMove = (event: MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    if (!mouseStarted || !boxRef?.current) {
      return;
    }

    mouseMoved = event.clientY - y;
  };

  const getSelectBoxClasses = () => {
    let className = 'selection-box';

    if (open) {
      className += ' shown';
    }

    return className;
  };

  const handleSelectKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
    const active = document.activeElement;

    if (
      (event.key !== 'ArrowDown' &&
        event.key !== 'ArrowUp' &&
        event.key !== 'Escape') ||
      !active
    ) {
      return;
    }

    event.stopPropagation();
    event.preventDefault();

    switch (event.key) {
      case 'ArrowDown':
        if (!active.nextElementSibling) {
          return;
        }

        (active.nextElementSibling as HTMLElement).focus();
        break;
      case 'ArrowUp':
        if (!active.previousElementSibling) {
          return;
        }

        (active.previousElementSibling as HTMLElement).focus();
        return;
      case 'Escape':
        toggleDrawer(false);
        return;
    }
  };

  useEffect(() => {
    if (!open) {
      return;
    }

    if (!props.value) {
      selectRef.current?.focus();
      return;
    }

    const index = props.options.indexOf(props.value);
    itemsRef.current[index]?.focus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <span data-testid={`selection-${props.label ? props.label : 'default'}`}>
      <Button
        sx={props.sx}
        onClick={() => {
          toggleDrawer(true);
        }}
        disabled={props.disabled}
        aria-haspopup="listbox"
        aria-label={`${props.placeholder}, ${props.label} ${props.value}`}
      >
        <LabelStyled>
          {props.label} &nbsp;
          <ValueDisplayStyled>{props.value}</ValueDisplayStyled>
          <SelectArrowDownStyled />
        </LabelStyled>
      </Button>
      <TransparentBackgroundStyled ref={overlayRef} className={'hide'} />

      <FocusTrap active={open}>
        <OverlayStyled
          onMouseDown={() => toggleDrawer(false)}
          onMouseUp={handleMouseUp}
          data-testid={`selection-drawer-${props.label}`}
          className={open ? 'shown' : ''}
          role="dialog"
          aria-hidden={!open}
        >
          <SelectionBoxStyled
            ref={boxRef}
            className={getSelectBoxClasses()}
            tabIndex={-1}
            onKeyDown={handleSelectKeyDown}
          >
            <PlaceHolderStyled
              onMouseDown={handleOnMouseDown}
              onMouseMove={handleOnMouseMove}
              onTouchStart={handleSwipeStart}
              onTouchMove={handleSwipeMove}
              onTouchEnd={handleSwipeEnd}
              className={'place-holder'}
              ref={selectRef}
            >
              <ArrowDownStyled />
              {props.placeholder}
            </PlaceHolderStyled>
            <SelectionsContainerStyled
              data-testid={`selection-drawer-options-${
                props.label ? props.label : 'default'
              }`}
              className={open ? 'shown' : ''}
              role="listbox"
              tabIndex={open ? 0 : -1}
            >
              {props.options.map((value, i) => {
                return (
                  <OptionRowStyled
                    ref={(el) => {
                      itemsRef.current[i] = el;
                    }}
                    key={`select-option-${value}`}
                    onMouseDown={handleClick}
                    onKeyDown={handleKeyDown}
                    className={
                      props.value === value ? 'option selected' : 'option'
                    }
                    tabIndex={open ? 0 : -1}
                    role="option"
                    aria-selected={props.value === value ? 'true' : 'false'}
                  >
                    {value}
                  </OptionRowStyled>
                );
              })}
            </SelectionsContainerStyled>
          </SelectionBoxStyled>
        </OverlayStyled>
      </FocusTrap>
    </span>
  );
};

export default Selection;
