import Snackbar, { SnackbarProps } from '@mui/material/Snackbar';
import ButtonBase from '@mui/material/ButtonBase';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import { KeyboardEvent } from 'react';
import FormatPrice from '../../features/Menu/components/FormatPrice/FormatPrice';
import { useCartV2 } from '../../hooks/useCartV2';
import { BotStatus } from '../../features/Cart/types';
interface SiteButtonProps {
  buttonLink?: string;
  buttonPrice?: number;
  buttonText: string;
  open: boolean;
  handlePress?: () => void;
  testId?: string;
  amountTestId?: string;
  descriptionTestId?: string;
}

const SiteButtonSectionDivider = styled(Box)(({ theme }) => ({
  borderTop: '1px solid',
  borderColor: theme.colors.gray[300],
  margin: 'auto',
  maxWidth: theme.breakpoints.values.lg
}));

const SiteButtonBackground = styled('div')(({ theme }) => ({
  backgroundColor: theme.colors.base.white,
  position: 'fixed',
  width: '100%',
  height: theme.spacing(20),
  bottom: 0,
  zIndex: 1300,
  margin: 'auto',
  maxWidth: theme.breakpoints.values.lg
}));

const SiteButtonSnackBar = styled(Snackbar)<SnackbarProps>(({ theme }) => ({
  color: theme.colors.partner.button.primary.font,
  height: theme.spacing(12),
  bottom: theme.spacing(4),
  margin: 'auto',
  maxWidth: theme.breakpoints.values.lg,
  cursor: 'pointer',
  [theme.breakpoints.up('sm')]: {
    bottom: theme.spacing(4),
    left: theme.spacing(4),
    right: theme.spacing(4)
  }
}));

const SiteButtonContent = styled('div')(({ theme }) => ({
  alignItems: 'center',
  backgroundColor: theme.colors.partner.button.primary.background,
  borderRadius: theme.spacing(2),
  display: 'flex',
  justifyContent: 'center',
  alignSelf: 'stretch',
  flex: 1,
  paddingLeft: theme.spacing(4),
  paddingRight: theme.spacing(4)
}));

const ButtonText = styled(Typography)(({ theme }) => ({
  textTransform: 'uppercase',
  color: theme.colors.partner.button.primary.font
}));

const ButtonPriceContainer = styled('div')(({}) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
}));

const ButtonPriceDivider = styled('div')(({ theme }) => ({
  marginRight: theme.spacing(2),
  marginLeft: theme.spacing(2)
}));

const SiteButton = ({
  buttonLink,
  buttonPrice,
  buttonText,
  open,
  handlePress,
  testId,
  descriptionTestId,
  amountTestId
}: SiteButtonProps) => {
  const navigate = useNavigate();
  const { isBot } = useCartV2();
  const handleClick = () => {
    if (!buttonLink) {
      return;
    }

    navigate(buttonLink);
  };

  const handleKeyPress = (e: KeyboardEvent<HTMLDivElement>) => {
    if (e.key !== 'Enter') {
      return;
    }

    if (handlePress) {
      handlePress();
      return;
    }

    handleClick();
  };

  return (
    <>
      <SiteButtonBackground>
        <SiteButtonSectionDivider />
      </SiteButtonBackground>
      <SiteButtonSnackBar
        role="button"
        open={open && isBot !== BotStatus.IS_BOT}
        onClick={handlePress || handleClick}
        onKeyUp={handleKeyPress}
        TransitionProps={{
          appear: false
        }}
      >
        <SiteButtonContent data-testid={testId} tabIndex={0}>
          {!buttonPrice && (
            <ButtonBase sx={{ width: '100%' }} type="submit">
              <ButtonText variant="body1" data-testid={descriptionTestId}>
                {buttonText}
              </ButtonText>
            </ButtonBase>
          )}
          <ButtonPriceContainer>
            {buttonPrice && (
              <>
                <ButtonText data-testid={descriptionTestId}>
                  {buttonText}
                </ButtonText>
                <ButtonPriceDivider>•</ButtonPriceDivider>
                <div data-testid={amountTestId}>{FormatPrice(buttonPrice)}</div>
              </>
            )}
          </ButtonPriceContainer>
        </SiteButtonContent>
      </SiteButtonSnackBar>
    </>
  );
};

export default SiteButton;
