import { ConfigInterface, PaymentGatewayType } from '../types/fetch';

export const emptyConfig: ConfigInterface = {
  colorConfig: {
    brand: {
      '50': '',
      '100': '',
      '200': '',
      '300': '',
      '400': '',
      '500': '',
      '600': '',
      '700': '',
      '800': '',
      '900': '',
      primary: '#4264d1'
    },
    font: {
      primary: '#ffffff'
    },
    button: {
      primary: {
        background: '#4264d1',
        outline: '',
        font: '#ffffff'
      },
      secondary: {
        background: '#4264d1',
        outline: '',
        font: '#ffffff'
      }
    }
  },
  partnerConfig: {
    partnerId: '',
    partnerName: '',
    shortCode: '',
    currency: 'USD',
    hasCapacity: false,
    hasDiscount: false,
    hasRoundUp: false,
    hasTip: false,
    roundUpTitle: '',
    roundUpDescription: '',
    warningFlags: [],
    timeZone: 'America/Denver'
  },
  locationConfig: {
    latitude: 0.0,
    longitude: 0.0
  },
  paymentGateway: {
    paymentGateway: PaymentGatewayType.Stripe,
    currency: '',
    country: '',
    merchantId: '',
    accountId: '',
    publicKey: ''
  }
};
