import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import { styled, useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import CircleDiv from '../../../../components/CircleDiv/CircleDiv';
import { default as Icon } from '../../../../components/AllergenIcon/AllergenIcon';
import { CartStatus } from '../../../Cart/types';
import { useCartV2 } from '../../../../hooks/useCartV2';
import { StoreInterface } from '../../../../types/stores';
import { ReactComponent as HandWaiveSVG } from '../../../../assets/images/logo-icons/waving-hand.svg';
import { usePickupTimes } from '../../../../hooks/usePickupTimes';
import CartButton from '../CartButton/CartButton';
interface CartTrackingDrawerProps {
  store: StoreInterface;
}

const ContentContainerStyled = styled('div')(({ theme }) => ({
  width: '100%',
  backgroundColor: 'white',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  paddingLeft: theme.spacing(4),
  paddingRight: theme.spacing(4),
  paddingTop: theme.spacing(4),
  paddingBottom: theme.spacing(6),
  borderTopLeftRadius: theme.spacing(2),
  borderTopRightRadius: theme.spacing(2),
  marginTop: theme.spacing(14),
  margin: 'auto',
  maxWidth: theme.breakpoints.values.lg
}));

const CartDrawerStyled = styled(Drawer)(() => ({
  '.MuiPaper-root': {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.05)' // transparent actually causes a color difference
  },
  zIndex: 3000
}));

const CartTrackingTitleStyled = styled(Typography)(({ theme }) => ({
  fontSize: theme.spacing(4),
  fontWeight: 700
}));

const CartTrackingMessageStyled = styled(Typography)(({ theme }) => ({
  fontSize: theme.spacing(3.5),
  marginBottom: theme.spacing(3),
  marginTop: theme.spacing(2),
  textAlign: 'center'
}));

const CartTrackingAddEmptyButtonStyled = styled(Button)(({ theme }) => ({
  borderColor: theme.colors.partner.primary,
  borderRadius: theme.spacing(2),
  color: theme.colors.partner.primary,
  fontSize: theme.spacing(4),
  fontWeight: 400,
  height: theme.spacing(12),
  marginBottom: theme.spacing(4),
  textTransform: 'capitalize',
  width: '100%'
}));

const CartTrackingBrowseButtonStyled = styled(Button)(({ theme }) => ({
  backgroundColor: theme.colors.partner.button.primary.background,
  color: theme.colors.partner.button.primary.font,
  borderRadius: theme.spacing(2),
  fontSize: theme.spacing(4),
  fontWeight: 400,
  height: theme.spacing(12),
  width: '100%',
  textTransform: 'capitalize'
}));

const CartButtonStyled = styled(CartButton)(() => ({
  bottom: 0
}));

const CartTrackingDrawer = ({ store }: CartTrackingDrawerProps) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { clearCart, setLastModified, getStatus, items, lastModified, setShowToggle, showToggle } =
    useCartV2();
  const { data: times, isFetched } = usePickupTimes(store.id);
  let trackingMessage = '';

  const [cartStatus, setCartStatus] = useState<
    CartStatus.EXPIRED | CartStatus.EXPIRING | CartStatus.ACTIVE
  >(() => getStatus());

  const [openTrackCartDrawer, setOpenTrackCartDrawer] = useState<boolean>(
    () => {
      if (cartStatus !== CartStatus.ACTIVE) {
        return true;
      }

      return (
        items.length > 1 &&
        cartStatus === CartStatus.ACTIVE &&
        (new Date().getTime() - new Date(lastModified).getTime()) / 60000 > 5
      );
    }
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setCartStatus(getStatus());
    }, 60000);

    return () => {
      if (!interval) {
        return;
      }

      clearInterval(interval);
    };
  }, [getStatus]);

  const expired = cartStatus === CartStatus.EXPIRED;
  const expiring = cartStatus === CartStatus.EXPIRING;
  const active = cartStatus === CartStatus.ACTIVE;

  if (expired || items.length < 1) {
    trackingMessage = t('restaurant.cart.expiredMsg');
  }

  if (expiring) {
    trackingMessage = t('restaurant.cart.expiringMsg');
  }

  if (active && items.length > 0) {
    trackingMessage = t('restaurant.cart.savedMsg');
  }

  const handleAddOrEmpty = () => {
    if (expiring) {
      clearCart();
      setShowToggle(showToggle);
    }

    setOpenTrackCartDrawer(false);
  };

  const handleBrowseMenu = () => {
    if (expired) {
      clearCart();
      setShowToggle(showToggle);
    }

    setLastModified();

    setOpenTrackCartDrawer(false);
  };

  if (isFetched && times && times.length <= 0) {
    return null;
  }

  return (
    <CartDrawerStyled
      open={openTrackCartDrawer}
      transitionDuration={1300}
      anchor="bottom"
      onClose={() => {
        handleBrowseMenu();
        setOpenTrackCartDrawer(false);
      }}
      data-testid={'cart-drawer'}
    >
      <ContentContainerStyled>
        <CircleDiv style={{ backgroundColor: theme.colors.partner.primary }}>
          <Icon
            AllergenSVG={HandWaiveSVG}
            height="40"
            width="26"
            ariaHidden={true}
          />
        </CircleDiv>
        <CartTrackingTitleStyled
          data-testid={`cart-tracking-title-${
            !expired && expiring
              ? t('restaurant.cart.stillHungry')
              : t('restaurant.cart.welcomeBack')
          }`}
        >
          {!expired && expiring
            ? t('restaurant.cart.stillHungry')
            : t('restaurant.cart.welcomeBack')}
        </CartTrackingTitleStyled>
        <CartTrackingMessageStyled
          data-testid={`tracking-message-${trackingMessage}`}
        >
          {trackingMessage}
        </CartTrackingMessageStyled>
        {!expired && items.length > 0 && (
          <CartTrackingAddEmptyButtonStyled
            variant="outlined"
            onClick={handleAddOrEmpty}
            data-testid={`cart-tracking-add-empty-button-${
              expiring ? 'emptyCart' : 'addMoreItems'
            }`}
          >
            {expiring
              ? t('restaurant.cart.emptyCart')
              : t('restaurant.cart.addMoreItems')}
          </CartTrackingAddEmptyButtonStyled>
        )}
        {!expired && items.length > 0 && <CartButtonStyled store={store} />}
        {(items.length < 1 || expired) && (
          <CartTrackingBrowseButtonStyled
            onClick={handleBrowseMenu}
            variant="contained"
            data-testid={'cart-tracking-browse-button'}
          >
            {t('restaurant.cart.browseMenu')}
          </CartTrackingBrowseButtonStyled>
        )}
      </ContentContainerStyled>
    </CartDrawerStyled>
  );
};

export default CartTrackingDrawer;
