import { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { useForm, SubmitHandler } from 'react-hook-form';
import CreditCardIframe from '../CreditCardIframe';
import initialize, {
  InitializePayload
} from '../../../../utils/Payments/FreedomPay/initialize';
import { useSiteConfig } from '../../../../hooks/useSiteConfig';
import BillingInfoDisplay from '../BillingInfoDisplay/BillingInfoDisplay';
import fetchCountries, {
  Country
} from '../../../../utils/Payments/FreedomPay/fetchCountries';
import { GatewayResponse } from '../../types/GatewayResponse';
import CollectBilling, {
  BillingFormValues
} from '../CollectBilling/CollectBilling';
import { GatewayError } from '../../GatewayError';

interface CreditCardProps {
  siteId: string;
  storeId: string;
  menuId: number;
  orderId: string;
  transactionTotal: number;
  orderTimeStamp: string;
  sessionTimeout: () => void;
  onError: (error: GatewayError) => void;
  onSuccess: (formResponse: GatewayResponse) => void;
}

const Container = styled('div')({
  zIndex: 1000,
  width: '100%'
});

const IframeContainer = styled('div', {
  shouldForwardProp: (prop: string) => prop !== 'isVisible'
})<{ isVisible: boolean }>(({ isVisible }) => ({
  width: '100%',
  marginTop: '16px',
  display: isVisible ? 'block' : 'none'
}));

const CreditCard = ({
  siteId,
  storeId,
  menuId,
  orderId,
  transactionTotal,
  orderTimeStamp,
  sessionTimeout,
  onError,
  onSuccess
}: CreditCardProps) => {
  const { partnerConfig } = useSiteConfig();
  const [billingInfo, setBillingInfo] = useState<BillingFormValues | null>(
    null
  );
  const [countries, setCountries] = useState<Country[]>([]);
  const [iframe, setIframe] = useState<string | null>();
  const [isEditing, setIsEditing] = useState<boolean>(true);
  const [isIframeVisible, setIsIframeVisible] = useState<boolean>(true);
  const { setError, clearErrors } = useForm<BillingFormValues>();

  const handleEditClick = () => {
    setIsEditing(true);
    setIsIframeVisible(false);
  };

  const handleCancelClick = () => {
    setIsEditing(false);
    setIsIframeVisible(true);
  };

  const billingOnSubmit: SubmitHandler<BillingFormValues> = async (
    formValues
  ) => {
    if (!formValues.country) {
      setError('country', {
        type: 'manual',
        message: 'Country is required'
      });
      return;
    } else {
      clearErrors('country');
    }

    setBillingInfo(formValues);
    setIsEditing(false);
    setIsIframeVisible(true);

    const initializePayload: InitializePayload = {
      OrderDetails: {
        TransactionTotal: transactionTotal,
        OrderNumber: Number(orderId),
        OrderDescription: 'Dine-In' // placeholder, make dynamic
      },
      BillingAddress1: formValues.address,
      BillingCity: formValues.city,
      BillingCountryCode: formValues.country.num3,
      BillingFirstName: formValues.firstName,
      BillingLastName: formValues.lastName,
      BillingPostalCode: formValues.postalCode,
      Email: formValues.email,
      MobilePhone: formValues.mobilePhone,
      Country: formValues.country.alpha2,
      disableConsumerAuthentication:
        transactionTotal === 55.22 || transactionTotal === 5.12 ? true : false // remove after cert
    };

    const response = await initialize(
      partnerConfig.partnerId,
      initializePayload
    );

    if (response?.iFrame) {
      setIframe(response.iFrame);
    }
  };

  useEffect(() => {
    const loadCountries = async () => {
      try {
        const data = await fetchCountries();
        setCountries(data);
      } catch (err) {
        console.error('Failed to fetch countries', err);
      }
    };
    loadCountries();
  }, []);

  return (
    <Container>
      {isEditing ? (
        <CollectBilling
          handleCancelClick={handleCancelClick}
          billingOnSubmit={billingOnSubmit}
          initialValues={billingInfo}
          countries={countries}
        />
      ) : (
        <>
          {billingInfo !== null && (
            <BillingInfoDisplay
              billingInfo={billingInfo}
              handleEditClick={handleEditClick}
            />
          )}
          {iframe && (
            <IframeContainer isVisible={isIframeVisible}>
              <CreditCardIframe
                orderId={orderId}
                siteId={siteId}
                storeId={storeId}
                menuId={menuId}
                nameOnCard={`${billingInfo?.firstName} ${billingInfo?.lastName}`}
                iframeHtml={iframe}
                orderTimeStamp={orderTimeStamp}
                sessionTimeout={sessionTimeout}
                onError={onError}
                onSuccess={onSuccess}
                transactionTotal={transactionTotal}
              />
            </IframeContainer>
          )}
        </>
      )}
    </Container>
  );
};

export default CreditCard;
