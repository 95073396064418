import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { styled, useTheme } from '@mui/material/styles';
import FoodPrepWindow from '../../../../components/FoodPrepWindow/FoodPrepWindow';
import { useSiteConfig } from '../../../../hooks/useSiteConfig';
import { useTimeDisplay } from '../../../../hooks/useTimeDisplay';
import { useStore } from '../../../../hooks/useStore';
import { PricingOptions } from '../../../Cart/types';

interface PickupInfoProps {
  restaurantName: string;
  isAdvancedOrdering: boolean;
  priceToDisplay: PricingOptions;
}

const PickupInfoContainer = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(4),
  paddingLeft: theme.spacing(4),
  paddingRight: theme.spacing(4)
}));

const PickupInfoTitle = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(1)
}));

const RestaurantTitle = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  fontWeight: 600
}));

const PickupTimeContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: theme.spacing(1)
}));

const BulletStyled = styled('span')(({ theme }) => ({
  position: 'relative',
  top: theme.spacing(0.1),
  paddingLeft: theme.spacing(1.5),
  paddingRight: theme.spacing(1.5)
}));

const PrepWindowText = styled(Typography, {
  shouldForwardProp: (prop: string) => prop !== 'color'
})<{ color: string }>(({ color }) => ({
  fontWeight: 'bold',
  color: color
}));

const PickupInfoText = styled(Typography)({});

const PickupInfo = ({ restaurantName, isAdvancedOrdering, priceToDisplay }: PickupInfoProps) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { partnerConfig: siteConfig } = useSiteConfig();
  const { data: restaurant } = useStore(
    siteConfig.partnerId,
    siteConfig.shortCode
  );
  const { pickupTimeDisplay } = useTimeDisplay(restaurant?.id ?? '');

  return (
    <PickupInfoContainer data-testid="pickup-info-container">
      <PickupInfoTitle variant="h5" data-testid="pickup-info-title">
          <>
            {priceToDisplay === PricingOptions.TAKEOUT
              ? t('restaurant.cart.takeAwayInfo')
              : t('restaurant.cart.dineInInfo')}
          </>
      </PickupInfoTitle>
      <RestaurantTitle variant="body1" data-testid="restaurant-name">
        {restaurantName}
      </RestaurantTitle>
      <PickupTimeContainer>
        {isAdvancedOrdering && (
          <>
            <FoodPrepWindow
              text={priceToDisplay === PricingOptions.TAKEOUT ? t('restaurant.cart.takeAwayTime') : t('restaurant.cart.dineInTime')}
              color={theme.colors.base.black}
            />
          </>
        )}

        {!isAdvancedOrdering && (
          <>
            <FoodPrepWindow
              text={priceToDisplay === PricingOptions.TAKEOUT ? t('restaurant.cart.takeAway') : t('restaurant.cart.dineIn')}
              color={theme.colors.base.black}
            />
          </>
        )}

      {isAdvancedOrdering && (
        <>
          <BulletStyled>•</BulletStyled>
          <PrepWindowText
            variant="body2"
            color={theme.colors.base.black}
            data-testid="pickup-time-text"
          >
            {pickupTimeDisplay}
          </PrepWindowText>
        </>
      )}
      </PickupTimeContainer>
      <PickupInfoText variant="body2" data-testid="pickup-info-text">
        {t('restaurant.checkout.pickupInfoText')}
      </PickupInfoText>
    </PickupInfoContainer>
  );
};

export default PickupInfo;
