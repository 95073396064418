export enum PaymentMethodType {
  Card = 'Card',
  ApplePay = 'Apple Pay',
  GooglePay = 'Google Pay'
}

export enum PaymentGatewayType {
  stripe = 'stripe',
  cardpointe = 'cardpointe',
  freedompay = 'freedompay'
}
export interface GatewayResponse {
  type: string;
  amount: number;
  confirmationId: string;
  gateway: PaymentGatewayType;
  paymentMethod: PaymentMethodType;
}
