import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { GatewayError } from '../GatewayError';
import {
  GatewayResponse,
  PaymentGatewayType,
  PaymentMethodType
} from '../types/GatewayResponse';
import { Controller, FieldValues, useForm } from 'react-hook-form';
import preAuth from '../../../utils/Payments/CardPointe/cardpointePreauth';
import { useSiteConfig } from '../../../hooks/useSiteConfig';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { generateIframeUrl } from './frameStyling';
import { COUNTRIES } from './countries';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import PlaceOrder from './PlaceOrder';
import { useTranslation } from 'react-i18next';
import { getCountryCode } from '../../../utils/Payments/CardPointe/getCountryCode';
import { CircularProgress, Typography } from '@mui/material';
import toast from 'react-hot-toast';
import { CardpointeErrorToast } from '../../../components/Toast/CardpointeErrorToast';
import CardPointeGooglePayButton from './CardPointeGooglePayButton';
import { getErrorDialogText } from '../../../utils/Payments/Stripe/errors';
import ApplePay from './ApplePay';
import { PaymentGateway } from '../../../types/fetch';
import { useCartV2 } from '../../../hooks/useCartV2';
import { BotStatus } from '../../Cart/types';

const HEADER_HEIGHT = '56px';
const PLACE_ORDER_HEIGHT = '88px';

interface FormValues {
  name: string;
  country: string;
  month: string;
  year: string;
  cvv: string;
  postal: string;
}

const Header = styled('div', {
  shouldForwardProp: (prop: string) => prop !== 'scrolled'
})<{ scrolled: boolean }>(({ theme, scrolled }) => ({
  backgroundColor: theme.colors.base.white,
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  position: 'fixed',
  top: 0,
  height: theme.spacing(14),
  width: '100%',
  zIndex: 10000,
  maxWidth: '1024px',
  boxShadow: scrolled ? '0px 1px 4px 0px rgba(0, 0, 0, 0.25)' : 'none',
  transition: 'box-shadow 0.3s ease'
}));

const StyledPaymentFormContainer = styled('div')(({ theme }) => ({
  paddingRight: theme.spacing(4),
  paddingLeft: theme.spacing(4),
  marginBottom: theme.spacing(28),
  overflowY: 'auto',
  position: 'fixed',
  top: HEADER_HEIGHT,
  bottom: 0,
  width: '100%',
  maxWidth: '1024px',
  height: `calc(100% - ${HEADER_HEIGHT} - ${PLACE_ORDER_HEIGHT})`
}));

const StyledDividerContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  marginTop: theme.spacing(5),
  marginBottom: theme.spacing(4),
  alignItems: 'center'
}));

const StyledDivider = styled('div')(({ theme }) => ({
  borderTop: '1px solid',
  borderColor: theme.colors.gray[300],
  width: '100%'
}));

const StyledOrText = styled(Typography)(({ theme }) => ({
  color: theme.colors.base.black,
  font: 'Roboto',
  marginLeft: theme.spacing(2),
  marginRight: theme.spacing(2),
  fontSize: theme.spacing(3)
}));

const indicatorSize = 20;
const LoadingSpinner = styled(CircularProgress)(({ theme }) => ({
  color: theme.colors.partner.primary,
  position: 'absolute',
  top: '50%',
  left: '50%',
  marginTop: `${-indicatorSize / 2}px`,
  marginLeft: `${-indicatorSize / 2}px`
}));

const InputField = styled(TextField)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  ' & input::placeholder': {
    color: '#667085',
    opacity: 1,
    font: 'Roboto',
    fontWeight: '400',
    lineHeight: theme.spacing(6),
    letterSpacing: '0.15px'
  },
  '.MuiOutlinedInput-notchedOutline': {
    border: '1px solid rgba(0, 0, 0, 0.12)'
  },
  '.MuiOutlinedInput-input': {
    paddingLeft: theme.spacing(4)
  }
}));

const InputLabel = styled(Typography)(({ theme }) => ({
  color: theme.colors.base.black,
  font: 'Roboto'
}));

const StyledDropdown = styled(Select)({
  marginBottom: '12px',
  height: '48px'
});

const ExpirationContainer = styled('div')({
  display: 'flex'
});

const LoadingContainer = styled('div')({
  position: 'absolute',
  top: '0px',
  zIndex: 10,
  width: '100%',
  height: '100%',
  backgroundColor: 'white'
});

interface CardPointeFormProps {
  onSubmit: () => void;
  onSuccess: (formResponse: GatewayResponse) => void;
  onError: (error: GatewayError) => void;
  amount: number;
  paymentGateway: PaymentGateway;
  storeName: string;
  handleClose: () => void;
}

const CardPointeForm = (props: CardPointeFormProps) => {
  const { t } = useTranslation();
  const { isBot } = useCartV2();

  const { partnerConfig: siteConfig } = useSiteConfig();
  const [activeField, setActiveField] = useState<undefined | keyof FormValues>(
    undefined
  );
  const [formData, setFormData] = useState<FormValues>({
    name: '',
    month: '',
    year: '',
    country: '',
    cvv: '',
    postal: ''
  });

  const {
    control,
    handleSubmit,
    watch,
    clearErrors,
    setValue,
    trigger,
    formState: { errors, isSubmitting }
  } = useForm<FormValues>({ defaultValues: { country: 'United States' } });

  const selectedCountry = watch('country');

  const [token, setToken] = useState('');
  const [errorCode, setErrorCode] = useState('');
  const [iframeLoaded, setIframeLoaded] = useState(false);

  const [applePayAvailable, setApplePayAvailable] = useState(false);

  const handleFormSubmit = async (data: FieldValues) => {
    if (isBot === BotStatus.IS_BOT) {
      return;
    }
    props.onSubmit();

    const preAuthData = {
      cardNotPresent: true,
      siteId: siteConfig.partnerId,
      account: token,
      expiry: data.year + data.month,
      amount: props.amount.toString(),
      name: data.name,
      country: getCountryCode(data.country),
      postal: data.postal,
      cvv: data.cvv
    };

    try {
      const cardPointeResponse = await preAuth(preAuthData);

      if (
        cardPointeResponse.retref &&
        typeof cardPointeResponse.retref === 'string'
      ) {
        props.onSuccess({
          type: 'credit_card',
          amount: props.amount,
          confirmationId: cardPointeResponse.retref,
          gateway: PaymentGatewayType.cardpointe,
          paymentMethod: PaymentMethodType.Card
        });
      } else {
        props.onError(
          new GatewayError(
            'declined',
            PaymentMethodType.Card,
            null,
            null,
            undefined
          )
        );
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      if (error.response.data.errors[0].respcode === '101') {
        const errorResponse = getErrorDialogText('expired_card');
        props.onError(
          new GatewayError(
            'declined',
            PaymentMethodType.Card,
            errorResponse.title,
            errorResponse.description,
            undefined
          )
        );
        return;
      }
      if (error.response.data.errors[0].respcode !== '000') {
        const errorResponse = getErrorDialogText('processing_error');
        props.onError(
          new GatewayError(
            'declined',
            PaymentMethodType.Card,
            errorResponse.title,
            errorResponse.description,
            undefined
          )
        );
        return;
      }
    }
  };

  const handleGooglePaySuccess = async (confirmationId: string) => {
    try {
      props.onSuccess({
        type: 'credit_card',
        amount: props.amount,
        confirmationId: confirmationId,
        gateway: PaymentGatewayType.cardpointe,
        paymentMethod: PaymentMethodType.GooglePay
      });
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      props.onError(
        new GatewayError(
          error.message,
          PaymentMethodType.GooglePay,
          null,
          null,
          undefined
        )
      );
    }
  };

  const handleApplePaySuccess = async (confirmationId: string) => {
    try {
      props.onSuccess({
        type: 'credit_card',
        amount: props.amount,
        confirmationId: confirmationId,
        gateway: PaymentGatewayType.cardpointe,
        paymentMethod: PaymentMethodType.ApplePay
      });
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      props.onError(
        new GatewayError(
          error.message,
          PaymentMethodType.ApplePay,
          null,
          null,
          undefined
        )
      );
    }
  };

  const onHandleFormChange = (event: ChangeEvent, field: keyof FormValues) => {
    const inputValue = (event.target as HTMLInputElement).value;
    setValue(field, inputValue);
    setActiveField(field);

    setFormData(() => {
      return {
        ...formData,
        [field]: inputValue
      };
    });
  };

  const handleClick = async () => {
    const isFormValid = await trigger();
    if (!isFormValid || errorCode !== '0' || !token) {
      toast(
        (toaster) => (
          <CardpointeErrorToast
            dismissToast={() => toast.dismiss(toaster.id)}
          />
        ),
        {
          duration: Infinity,
          style: {
            padding: 0,
            height: '48px',
            backgroundColor: '#1a1a1a',
            width: '100%'
          },
          position: 'bottom-center'
        }
      );
    } else {
      await handleSubmit(handleFormSubmit)();
    }
  };

  const validateActiveField = async (field: keyof FormValues) => {
    const isActiveFieldValid = await trigger(field);
    if (isActiveFieldValid) {
      clearErrors(field);
    }
  };

  useEffect(() => {
    const handleTokenEvent = (event: MessageEvent) => {
      try {
        const tokenData = JSON.parse(event.data);
        setToken(tokenData.token);
        setErrorCode(tokenData.errorCode);
      } catch (error) {
        console.error('Error parsing token data:', error);
      }
    };

    window.addEventListener('message', handleTokenEvent, false);

    return () => {
      window.removeEventListener('message', handleTokenEvent, false);
    };
  }, []);

  useEffect(() => {
    if (activeField) {
      validateActiveField(activeField);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData]);

  useEffect(() => {
    const checkApplePaySupport = async () => {
      if (window.ApplePaySession) {
        const hasActiveCard =
          await window.ApplePaySession.canMakePaymentsWithActiveCard(
            props.paymentGateway?.applePay?.merchantId || ''
          );

        setApplePayAvailable(hasActiveCard);
      } else {
        setApplePayAvailable(false);
      }
    };

    checkApplePaySupport();
  }, [props.paymentGateway?.applePay?.merchantId]);

  const [scrolled, setScrolled] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  const handleScroll = () => {
    if (containerRef.current && containerRef.current.scrollTop > 0) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      container.addEventListener('scroll', handleScroll);
      return () => {
        container.removeEventListener('scroll', handleScroll);
      };
    }
  }, []);

  return (
    <>
      <Header scrolled={scrolled}>
        <IconButton
          aria-label="close"
          onClick={props.handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8
          }}
        >
          <CloseIcon />
        </IconButton>
      </Header>
      <StyledPaymentFormContainer ref={containerRef}>
        {applePayAvailable && (
          <>
            <ApplePay
              siteId={siteConfig.partnerId}
              amount={props.amount.toString()}
              onError={props.onError}
              onSuccess={handleApplePaySuccess}
            />
            <StyledDividerContainer>
              <StyledDivider />
              <StyledOrText>{t('restaurant.checkout.or')}</StyledOrText>
              <StyledDivider />
            </StyledDividerContainer>
          </>
        )}
        <CardPointeGooglePayButton
          siteId={siteConfig.partnerId}
          merchantId={props.paymentGateway.merchantId}
          merchantName={props.storeName}
          amount={props.amount}
          onSuccess={handleGooglePaySuccess}
          onError={props.onError}
        />
        <StyledDividerContainer>
          <StyledDivider />
          <StyledOrText>{t('restaurant.checkout.or')}</StyledOrText>
          <StyledDivider />
        </StyledDividerContainer>
        <form
          name="tokenForm"
          id="tokenForm"
          onSubmit={handleSubmit(handleFormSubmit)}
          data-testid="tokenForm"
          style={{
            width: '100%'
          }}
        >
          <InputLabel variant="subtitle1">
            {t('restaurant.checkout.cardholderName')}
          </InputLabel>
          <Controller
            name="name"
            control={control}
            rules={{
              required: `${t('restaurant.checkout.cardholderName')} ${t(
                'restaurant.checkout.isRequired'
              )}`
            }}
            render={({ field }) => (
              <InputField
                {...field}
                autoComplete="name"
                placeholder={t('restaurant.checkout.enterName')}
                fullWidth
                error={!!errors.name}
                onChange={(e) => onHandleFormChange(e, 'name')}
                InputProps={{ style: { height: '48px' } }}
                helperText={errors.name?.message}
              />
            )}
          />
          <InputLabel variant="subtitle1">
            {t('restaurant.checkout.cardNumber')}
          </InputLabel>
          <iframe
            title="tokenFrame"
            id="tokenFrame"
            name="tokenFrame"
            style={{
              width: '100%',
              height: '56px',
              border: 'none',
              fontFamily: 'sans-serif'
            }}
            data-testid="tokenFrame"
            onLoad={() => {
              setTimeout(() => {
                setIframeLoaded(true);
              }, 150);
            }}
            src={generateIframeUrl()}
          />
          <InputLabel variant="subtitle1">
            {t('restaurant.checkout.expirationDate')}
          </InputLabel>
          <ExpirationContainer>
            <Controller
              name="month"
              control={control}
              rules={{
                required: `${t('restaurant.checkout.expirationDateMonth')} ${t(
                  'restaurant.checkout.isRequired'
                )}`,
                pattern: {
                  value: /^\d{1,2}$/,
                  message: `${t('restaurant.checkout.expirationDateMonth')} ${t(
                    'restaurant.checkout.isRequired'
                  )}`
                }
              }}
              render={({ field }) => (
                <InputField
                  {...field}
                  fullWidth
                  error={!!errors.month}
                  onChange={(e) => onHandleFormChange(e, 'month')}
                  placeholder={t('restaurant.checkout.MM')}
                  autoComplete="cc-exp-month"
                  InputProps={{ style: { height: '48px' } }}
                  inputProps={{
                    inputMode: 'numeric',
                    maxLength: 2,
                    pattern: '[0-9]*'
                  }}
                  helperText={errors.month?.message}
                />
              )}
            />
            <Controller
              name="year"
              control={control}
              rules={{
                minLength: {
                  value: 4,
                  message: `${t('restaurant.checkout.expirationDateYear')} ${t(
                    'restaurant.checkout.isRequired'
                  )}`
                },
                maxLength: {
                  value: 4,
                  message: `${t('restaurant.checkout.expirationDateYear')} ${t(
                    'restaurant.checkout.isRequired'
                  )}`
                },
                pattern: {
                  value: /^\d{4}$/,
                  message: `${t('restaurant.checkout.expirationDateYear')} ${t(
                    'restaurant.checkout.isRequired'
                  )}`
                },
                required: `${t('restaurant.checkout.expirationDateYear')} ${t(
                  'restaurant.checkout.isRequired'
                )}`
              }}
              render={({ field }) => (
                <InputField
                  {...field}
                  fullWidth
                  error={!!errors.year}
                  onChange={(e) => onHandleFormChange(e, 'year')}
                  autoComplete="cc-exp-year"
                  placeholder={t('restaurant.checkout.YYYY')}
                  InputProps={{ style: { height: '48px' } }}
                  inputProps={{
                    inputMode: 'numeric',
                    minLength: 4,
                    maxLength: 4,
                    pattern: '[0-9]*'
                  }}
                  helperText={errors.year?.message}
                />
              )}
            />
          </ExpirationContainer>
          <InputLabel variant="subtitle1">
            {t('restaurant.checkout.cvv')}
          </InputLabel>
          <Controller
            name="cvv"
            control={control}
            rules={{
              required: `${t('restaurant.checkout.cvv')} ${t(
                'restaurant.checkout.isRequired'
              )}`
            }}
            render={({ field }) => (
              <InputField
                {...field}
                fullWidth
                error={!!errors.cvv}
                onChange={(e: ChangeEvent) => onHandleFormChange(e, 'cvv')}
                autoComplete="cc-csc"
                placeholder={t('restaurant.checkout.enterCVV')}
                InputProps={{ style: { height: '48px' } }}
                inputProps={{
                  inputMode: 'numeric',
                  maxLength: 4,
                  pattern: '[0-9]*'
                }}
                helperText={errors.cvv?.message}
              />
            )}
          />
          <InputLabel variant="subtitle1">
            {t('restaurant.checkout.country')}
          </InputLabel>
          <FormControl fullWidth error={!!errors.country}>
            <Controller
              name="country"
              control={control}
              rules={{
                required: `${t('restaurant.checkout.country')} ${t(
                  'restaurant.checkout.isRequired'
                )}`
              }}
              render={({ field }) => (
                <StyledDropdown
                  {...field}
                  MenuProps={{
                    style: {
                      zIndex: 1500,
                      marginBottom: '450px',
                      marginTop: '-230px'
                    }
                  }}
                  displayEmpty
                  onChange={field.onChange}
                >
                  {Object.values(COUNTRIES).map((country, index) => (
                    <MenuItem key={index} value={country}>
                      {country}
                    </MenuItem>
                  ))}
                </StyledDropdown>
              )}
            />
          </FormControl>
          <InputLabel variant="subtitle1">
            {selectedCountry === 'United States'
              ? t('restaurant.checkout.zipCode')
              : t('restaurant.checkout.postalCode')}
          </InputLabel>
          <Controller
            name="postal"
            control={control}
            rules={{
              required:
                selectedCountry === 'United States'
                  ? `${t('restaurant.checkout.zipCode')} ${t(
                      'restaurant.checkout.isRequired'
                    )}`
                  : `${t('restaurant.checkout.postalCode')} ${t(
                      'restaurant.checkout.isRequired'
                    )}`
            }}
            render={({ field }) => (
              <InputField
                {...field}
                fullWidth
                error={!!errors.postal}
                onChange={(e) => onHandleFormChange(e, 'postal')}
                inputProps={{
                  inputMode: 'numeric',
                  maxLength: 10,
                  pattern: '[0-9]*'
                }}
                autoComplete="postal-code"
                placeholder={
                  selectedCountry === 'United States'
                    ? t('restaurant.checkout.enterZipCode')
                    : t('restaurant.checkout.enterPostalCode')
                }
                InputProps={{ style: { height: '48px' } }}
                helperText={errors.postal?.message}
              />
            )}
          />
          <input type="hidden" name="mytoken" id="mytoken" value={token} />
        </form>
      </StyledPaymentFormContainer>
      {!iframeLoaded && (
        <LoadingContainer>
          <LoadingSpinner size={indicatorSize} />
        </LoadingContainer>
      )}
      <PlaceOrder handlePress={handleClick} disabled={isSubmitting} />
    </>
  );
};

export default CardPointeForm;
