import { styled, Typography } from '@mui/material';
import { useEffect, useRef } from 'react';
import { StoreInterface } from '../../../../types/stores';
import MenuItemCard from '../MenuItemCard';
import MenuCategoryObserverFactory from '../../../../utils/Menu/MenuCategoryObserverFactory';
import { MenuItem } from '../../types';
import { PricingOptions } from '../../../Cart/types';

export interface MenuCategoryProps {
  content: MenuItem[];
  category: string;
  screenTracker: (category: string) => void;
  store: StoreInterface;
  priceToDisplay?: PricingOptions;
}

const CategoryLabel = styled(Typography)(({ theme }) => ({
  fontSize: theme.spacing(6),
  marginBottom: theme.spacing(4),
  marginTop: theme.spacing(6)
}));

const MenuCategory = ({
  content,
  category,
  screenTracker,
  store,
  priceToDisplay
}: MenuCategoryProps) => {
  const menuCategoryRef = useRef(null);
  const formatCategory = (c: string) => {
    // Extract numbers from the beginning of the string
    const match = c.match(/^\d+/);
    const numberAtStart = match ? match[0] : '';

    // Remove numbers from the beginning of the string
    let str = c.replace(/^\d+/, '');

    // Remove any non-alphanumeric characters except spaces
    str = str.replace(/[^\w\s]/g, '');

    // Replace spaces with dashes
    str = str.replace(/\s+/g, '-');

    // Append the number at the end if it exists
    if (numberAtStart) {
      str += '-' + numberAtStart;
    }

    return str;
  };
  const categoryClass = formatCategory(category);

  useEffect(() => {
    /* istanbul ignore next */
    if (!menuCategoryRef.current) {
      return;
    }

    const menuCatRef = menuCategoryRef.current;
    const observer = MenuCategoryObserverFactory({ screenTracker, category });
    observer.observe(menuCatRef);

    return () => {
      observer.unobserve(menuCatRef);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <section
      id={`#${categoryClass}`}
      className={`${categoryClass}`}
      data-testid={`menu-category-${categoryClass}`}
      ref={menuCategoryRef}
    >
      <CategoryLabel
        data-testid={`menu-category-label-${categoryClass}`}
        variant="h3"
        tabIndex={0}
      >
        {category}
      </CategoryLabel>
      {content.map((filteredItem) => (
        <MenuItemCard
          key={filteredItem.productId}
          menuItem={filteredItem}
          storeId={store.shortId}
          priceToDisplay={priceToDisplay}
        />
      ))}
    </section>
  );
};

export default MenuCategory;
