import axios from 'axios';
import { backendConfig } from '../../../config/appConfig';
import { FREEDOM_PAY } from '../../../config/backendServicesEndpoints';

interface AppleInitializePayload {
  TotalPrice: string;
}

const appleInitialize = async (
  siteId: string,
  initializePayload: AppleInitializePayload
) => {
  const initializeUrl = `${FREEDOM_PAY}/site/${siteId}/initialize/applePay`;

  const requestBody = JSON.stringify(initializePayload);

  const axiosConfig = {
    method: 'POST',
    url: initializeUrl,
    headers: {
      'Content-Type': 'application/json',
      'x-api-key': backendConfig.apiKey
    },
    data: requestBody
  };
  try {
    const response = await axios(axiosConfig);
    return response.data;
  } catch (err) {
    throw err;
  }
};

export default appleInitialize;
