import { FC } from 'react';
import {
  generatePath,
  useNavigate,
  useParams,
  useSearchParams
} from 'react-router-dom';
import { useStore } from '../../hooks/useStore';
import { useSiteConfig } from '../../hooks/useSiteConfig';
import AppRoute from '../../routing/AppRoute';
import OrderModal from './components/OrderModal/OrderModal';
import { Helmet } from 'react-helmet';
import { useOrderStatus } from '../../hooks/useOrderStatus';
import { ThemeProvider } from '@mui/material';
import { useColorConfig } from '../../hooks/useColorConfig';
import AppLayout from '../../layouts/AppLayout';
import { OrderType } from '../../types/order';
import { PricingOptions } from '../Cart/types';

const OrderStatus: FC = () => {
  const { partnerConfig: siteConfig } = useSiteConfig();
  const [searchParams] = useSearchParams();
  const publicOrderId = searchParams.get('g');
  const { storeId } = useParams();
  const navigate = useNavigate();
  const { data: store } = useStore(siteConfig.partnerId, storeId ?? '');
  const { data: order } = useOrderStatus(
    publicOrderId ?? '',
    store ? store.id : ''
  );
  const theme = useColorConfig();


  const navigateToMenu = () => {
    navigate(
      generatePath(AppRoute.MENU, {
        storeId: String(storeId)
      })
    );
  };

  if (!publicOrderId) {
    navigateToMenu();
    return null;
  }

  if (!store || !store.id || !order) {
    return null;
  }

  return (
    <ThemeProvider theme={theme}>
      <AppLayout>
        <Helmet>
          <title>Order Status</title>
        </Helmet>
        <OrderModal
          order={order.data}
          store={store}
          priceToDisplay={
            order.data.orderType === OrderType.takeOut
              ? PricingOptions.TAKEOUT
              : PricingOptions.DINEIN
          }
        />
      </AppLayout>
    </ThemeProvider>
  );
};

export default OrderStatus;
