import { styled, useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import FoodPrepWindow from '../../../../components/FoodPrepWindow/FoodPrepWindow';
import { format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { useSiteConfig } from '../../../../hooks/useSiteConfig';
import { StoreInterface } from '../../../../types/stores';
import { PricingOptions } from '../../../Cart/types';

interface OrderInfoProps {
  isOrderComplete: boolean;
  orderNumber: number | string;
  pickupTime: Date | null;
  store: StoreInterface;
  location: string | undefined;
  priceToDisplay: PricingOptions;
}

const OrderInfoContainer = styled(Box)(({}) => ({
  backgroundColor: '#F7F7F8',
  display: 'flex',
  flexDirection: 'column',
  padding: 16
}));

const OrderLocationContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  marginTop: theme.spacing(2)
}));

const OrderLocation = styled(Typography)(({}) => ({}));

const OrderLocationBold = styled(Typography)(({ theme }) => ({
  fontSize: theme.spacing(4),
  fontWeight: 600
}));

const OrderNumberContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  marginTop: theme.spacing(location ? 1 : 2)
}));

const OrderNumber = styled(Typography)(({}) => ({}));

const OrderNumberBold = styled(Typography)(({ theme }) => ({
  fontSize: theme.spacing(4),
  fontWeight: 600
}));

const PickupInfoContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  marginBottom: theme.spacing(1),
  marginTop: theme.spacing(1),
  fontSize: '16px',
  fontWeight: 600
}));

const SectionDivider = styled(Box)(({ theme }) => ({
  borderTop: '1px solid #D0D5DD',
  color: theme.colors.base.black,
  marginBottom: theme.spacing(3),
  marginTop: theme.spacing(3)
}));

const BulletStyled = styled('span')(({ theme }) => ({
  position: 'relative',
  top: theme.spacing(0.1),
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1)
}));

const OrderHeaderStyled = styled('h3')(({ theme }) => ({
  fontSize: theme.spacing(6),
  padding: 0,
  margin: 0
}));

const StoreHeaderStyled = styled('h4')(({ theme }) => ({
  fontSize: theme.spacing(5),
  padding: 0,
  margin: 0
}));

const InfoSubtext = styled(Typography)(() => ({}));

const OrderInfo = ({
  isOrderComplete,
  orderNumber,
  pickupTime,
  store,
  location,
  priceToDisplay
}: OrderInfoProps) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { partnerConfig: siteConfig } = useSiteConfig();

  return (
    <OrderInfoContainer data-testid="order-info">
      <OrderHeaderStyled>{t('restaurant.order.details')}</OrderHeaderStyled>
      <StoreHeaderStyled data-testid="order-info-name">
        {store.name}
      </StoreHeaderStyled>
      {location && (
        <OrderLocationContainer>
          <OrderLocation
            variant="body1"
            data-testid="order-location-text"
          >{`${t('restaurant.order.location')}:\u00A0`}</OrderLocation>
          <OrderLocationBold data-testid="order-location">
            {location}
          </OrderLocationBold>
        </OrderLocationContainer>
      )}
      <OrderNumberContainer>
        <OrderNumber variant="body1" data-testid="order-number-text">{`${t(
          'restaurant.main.order'
        )} #\u00A0`}</OrderNumber>
        <OrderNumberBold data-testid="order-number">
          {orderNumber}
        </OrderNumberBold>
      </OrderNumberContainer>
      <PickupInfoContainer>
        { store.hasAdvancedOrdering && (
            <FoodPrepWindow
              text={t('restaurant.order.requestedTime')}
              color={theme.colors.base.black}
              fontSize={'16px'}
            />
          )
        }
        { !store.hasAdvancedOrdering && (
          <FoodPrepWindow
            text={
              isOrderComplete
                ? t('restaurant.order.requestedTime')
                : priceToDisplay === PricingOptions.TAKEOUT
                  ? t('restaurant.cart.takeAway')
                  : t('restaurant.cart.dineIn')
            }
            color={theme.colors.base.black}
            fontSize={'16px'}
          />
        )}
        <BulletStyled>•</BulletStyled>
        {pickupTime
          ? format(utcToZonedTime(pickupTime, siteConfig.timeZone), 'h:mm a')
          : 'ASAP'}
      </PickupInfoContainer>
      <SectionDivider />
      <InfoSubtext variant="body2" data-testid="order-status">
        {location
          ? t('restaurant.order.orderInstructionsDelivery')
          : isOrderComplete
          ? t('restaurant.order.orderInstructionsComplete')
          : t('restaurant.order.orderInstructionsConfirmed')}
      </InfoSubtext>
    </OrderInfoContainer>
  );
};

export default OrderInfo;
