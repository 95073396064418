declare global {
  interface Window {
    DATADOG_ENV?: string;
    DATADOG_SERVICE?: string;
    REACT_APP_ENVIRONMENT?: string;
    REACT_APP_NAME?: string;
    REACT_APP_ID?: string;
    REACT_APP_IGNITE_API_KEY?: string;
    REACT_APP_IGNITE_BASE_URL?: string;
    REACT_APP_FIREBASE_API_KEY?: string;
    REACT_APP_FIREBASE_PROJECT_ID?: string;
    REACT_APP_FIREBASE_STORAGE_BUCKET?: string;
    REACT_APP_FIREBASE_MESSAGING_SENDER_ID?: string;
    REACT_APP_FIREBASE_APP_ID?: string;
    REACT_APP_FIREBASE_MEASUREMENT_ID?: string;
    REACT_APP_FIREBASE_AUTH_DOMAIN?: string;
    REACT_APP_ITEM_SPECIAL_INSTRUCTIONS_LIMIT?: string;
    REACT_APP_GOOGLE_PAY_MERCHANT_ID?: string;
    REACT_APP_GOOGLE_PAY_ENVIRONMENT?: string;
    REACT_APP_RECAPTCHA_SITE_KEY: string;
    REACT_APP_FREEDOMPAY_SCRIPT: string;
    SUPPORT_EMAIL?: string;
    VERSION?: string;
    BRANCH?: string;
  }
}

export const appConfig = {
  environment: window.REACT_APP_ENVIRONMENT || '',
  appName: window.REACT_APP_NAME || '',
  datadogEnv: window.DATADOG_ENV || '',
  datadogService: window.DATADOG_SERVICE || '',
  appId: window.REACT_APP_ID || '',
  stripeTestAccountId: 'acct_1LT8QGQo4ogIFLAC', // TODO: remove this once GooglePay issue resolved
  version: window.VERSION || '',
  branch: window.BRANCH || '',
  googlePayMerchantId: window.REACT_APP_GOOGLE_PAY_MERCHANT_ID || '',
  googlePayEnv: window.REACT_APP_GOOGLE_PAY_ENVIRONMENT || 'TEST',
  supportEmail: window.SUPPORT_EMAIL || 'support@cinchio.com',
  reactRecaptchaSiteKey: window.REACT_APP_RECAPTCHA_SITE_KEY || '',
  freedomPayScript: window.REACT_APP_FREEDOMPAY_SCRIPT || ''
};

export const backendConfig = {
  apiKey: window.REACT_APP_IGNITE_API_KEY || '',
  apiBaseUrl: window.REACT_APP_IGNITE_BASE_URL || ''
};

export const firebaseConfig = {
  apiKey: window.REACT_APP_FIREBASE_API_KEY || '',
  authDomain: window.REACT_APP_FIREBASE_AUTH_DOMAIN || '',
  projectId: window.REACT_APP_FIREBASE_PROJECT_ID || '',
  storageBucket: window.REACT_APP_FIREBASE_STORAGE_BUCKET || '',
  messagingSenderId: window.REACT_APP_FIREBASE_MESSAGING_SENDER_ID || '',
  appId: window.REACT_APP_FIREBASE_APP_ID || '',
  measurementId: window.REACT_APP_FIREBASE_MEASUREMENT_ID || ''
};

export const menuConfig = {
  specialInstructionsLimit: Number(
    window.REACT_APP_ITEM_SPECIAL_INSTRUCTIONS_LIMIT || 40
  )
};
