import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import SelectPaymentMethod from '../SelectPaymentMethod';
import { useEffect, useRef, useState } from 'react';
import { GatewayResponse } from '../../types/GatewayResponse';
import { GatewayError } from '../../GatewayError';

const HEADER_HEIGHT = '56px';

interface FreedomPayProps {
  siteId: string;
  storeId: string;
  menuId: number;
  orderId: string;
  transactionTotal: number;
  handleClose: () => void;
  orderTimeStamp: string;
  sessionTimeout: () => void;
  onError: (error: GatewayError) => void;
  onSuccess: (formResponse: GatewayResponse) => void;
}

const Header = styled('div', {
  shouldForwardProp: (prop: string) => prop !== 'scrolled'
})<{ scrolled: boolean }>(({ theme, scrolled }) => ({
  backgroundColor: theme.colors.base.white,
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  position: 'fixed',
  top: 0,
  width: '100%',
  maxWidth: '1024px',
  zIndex: 30000,
  boxShadow: scrolled ? '0px 1px 4px 0px rgba(0, 0, 0, 0.25)' : 'none',
  height: '56px',
  transition: 'box-shadow 0.3s ease',
  '.closeIcon': {
    position: 'relative'
  }
}));

const FreedomPayContainer = styled('div')(({ theme }) => ({
  padding: theme.spacing(4),
  marginBottom: theme.spacing(4),
  overflowY: 'auto',
  position: 'fixed',
  top: HEADER_HEIGHT,
  bottom: 0,
  width: '100%',
  maxWidth: '1024px',
  height: `calc(100% - ${HEADER_HEIGHT})`
}));

const FreedomPay = ({
  siteId,
  storeId,
  menuId,
  orderId,
  transactionTotal,
  handleClose,
  orderTimeStamp,
  sessionTimeout,
  onError,
  onSuccess
}: FreedomPayProps) => {
  const [scrolled, setScrolled] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  const handleScroll = () => {
    if (containerRef.current && containerRef.current.scrollTop > 0) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      container.addEventListener('scroll', handleScroll);
      return () => {
        container.removeEventListener('scroll', handleScroll);
      };
    }
  }, []);

  return (
    <>
      <Header data-test-id="freedom-pay-header" scrolled={scrolled}>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8
          }}
        >
          <CloseIcon className="closeIcon" />
        </IconButton>
      </Header>
      <FreedomPayContainer
        data-testid="freedom-pay-container"
        ref={containerRef}
      >
        <SelectPaymentMethod
          orderId={orderId}
          siteId={siteId}
          menuId={menuId}
          storeId={storeId}
          transactionTotal={transactionTotal}
          orderTimeStamp={orderTimeStamp}
          sessionTimeout={sessionTimeout}
          onError={onError}
          onSuccess={onSuccess}
        />
      </FreedomPayContainer>
    </>
  );
};

export default FreedomPay;
