import { CartItemModifier, PricingOptions } from '../../features/Cart/types';

const getFullPrice = (
  price: number,
  quantity: number,
  modifiers: {
    [index: string]: CartItemModifier;
  },
  priceToDisplay?: PricingOptions
) => {
  for (const key in modifiers) {
    const priceKey =
      priceToDisplay === PricingOptions.TAKEOUT
        ? 'takeOutPrice'
        : 'dineInPrice';
    const modPrice = modifiers[key][priceKey];
    const qty = modifiers[key].quantity;

    if (!modPrice) {
      continue;
    }

    price += modPrice * qty;
  }
  price = price * quantity;
  return price;
};

export default getFullPrice;
