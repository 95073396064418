import { styled } from '@mui/material/styles';
import { ReactComponent as WarningIcon } from '../../assets/images/logo-icons/warning-circle.svg';

// Enum for styling future banner types
// Ex: Error, Success, etc
export enum BannerType {
  warning = 'warning'
}

export interface BannerProps {
  bannerText: string;
  bannerType?: BannerType;
  bannerSize?: string;
}

const StyledBannerContainer = styled('div', {
  shouldForwardProp: (prop: string) => prop !== 'size'
})<{ size: string }>(({ theme, size }) => ({
  borderRadius: theme.spacing(2),
  border: '1px solid',
  display: 'flex',
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(3.5),
  width: size,

  '&.banner-type-warning': {
    borderColor: theme.colors.warning[500],
    background: theme.colors.warning[50]
  }
}));

const StyledBanner = styled('div')(({ theme }) => ({
  padding: theme.spacing(3),
  display: 'inline-flex',
  alignItems: 'center',
  fontSize: theme.spacing(3.5),

  '.banner-type-warning &': {
    color: theme.colors.warning[700]
  }
}));

const StyledWarningIconContainer = styled('div')(({ theme }) => ({
  paddingRight: theme.spacing(2),
  display: 'flex'
}));

export default function Banner({
  bannerType,
  bannerText,
  bannerSize
}: BannerProps) {
  return (
    <StyledBannerContainer
      data-testid="banner"
      className={`banner-type-${bannerType || BannerType.warning}`}
      size={bannerSize || '100%'}
    >
      <StyledBanner>
        <StyledWarningIconContainer>
          <WarningIcon />
        </StyledWarningIconContainer>
        {bannerText}
      </StyledBanner>
    </StyledBannerContainer>
  );
}
