import { backendConfig } from './appConfig';

export const BASE_URL = backendConfig.apiBaseUrl;
export const STORES = `${BASE_URL}/v2/eat/site`;
export const MENU = `${BASE_URL}/v5/eat/menu/store`;
// export const ORDER = `${BASE_URL}/order`;
export const ORDER_STATUS = `${BASE_URL}/eat/order/store`;
export const CONFIG_V3 = `${BASE_URL}/v3/eat/config/site`;
export const CALCULATE = `${BASE_URL}/v5/eat/order/calculate`;
export const PROCESS = `${BASE_URL}/v5/eat/order/process`;
export const PICKUP_TIMES = `${BASE_URL}/eat/order/time/restaurant`;
export const PHONE_VALIDATION = `${BASE_URL}/eat/phone/validate`;
// export const PARTNER = `${BASE_URL}/eat/partner`;
export const PHONE_COUNTRIES = `${BASE_URL}/eat/phone/country`;
export const FREEDOM_PAY = `${BASE_URL}/eat/payment/freedomPay`;
