import { FC } from 'react';
import { CartItemModifier, PricingOptions } from '../../features/Cart/types';
import FormatPrice from '../../features/Menu/components/FormatPrice/FormatPrice';
import { useCartV2 } from '../../hooks/useCartV2';

interface ModifierItemProps {
  modifier: CartItemModifier;
  className?: string;
}

const ModifierItem: FC<ModifierItemProps> = (props) => {
  const { priceToDisplay } = useCartV2();
  const { modifier, className } = props;
  const priceDisplay =
    priceToDisplay === PricingOptions.TAKEOUT
      ? modifier.takeOutPrice
      : modifier.dineInPrice;

  let itemString = '';

  if (props.modifier.quantity > 1) {
    itemString += `(${modifier.quantity}) `;
  }

  itemString += modifier.name;

  if (priceDisplay && priceDisplay > 0) {
    itemString += ` (+ ${FormatPrice(priceDisplay)})`;
  }
  return <span className={className}>{itemString}</span>;
};

export default ModifierItem;
