import React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { useSiteConfig } from '../../hooks/useSiteConfig';
// import EatHeader from './components/EatHeader';
import SiteHeader from './components/SiteHeader';
interface AppLayoutProps {
  children: React.ReactNode | React.ReactNode[];
  shouldDisplay?: boolean;
}

const AppWrapper = styled(Box)({
  height: '100vh',
  width: '100%',
  display: 'flex',
  justifyContent: 'center'
});

const AppContainer = styled(Box)(({ theme }) => ({
  maxWidth: theme.breakpoints.values.lg,
  width: '100%'
}));

const AppLayout = ({ children, shouldDisplay }: AppLayoutProps) => {
  const { partnerConfig: siteConfig } = useSiteConfig();

  const isSiteHeader = () => {
    return siteConfig?.partnerId;
  };

  const isVisible = () => {
    if (shouldDisplay === undefined) {
      shouldDisplay = true;
    }

    return shouldDisplay;
  };

  return (
    <AppWrapper>
      <AppContainer>
        {isVisible() && isSiteHeader() && <SiteHeader />}
        {children}
      </AppContainer>
    </AppWrapper>
  );
};

export default AppLayout;
