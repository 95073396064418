import { useContext } from 'react';
import { CartContextV2 } from '../contexts/Cart/CartContextV2';
import { BotStatus, CartItem, PricingOptions, Tip } from '../features/Cart/types';
import getCartStatus from '../utils/Cart/getCartStatus';

export const useCartV2 = () => {
  const {
    items,
    dispatch,
    lastModified,
    pickupTime,
    promoCode,
    tip,
    hasRoundUp,
    shortId,
    priceToDisplay,
    showToggle,
    isBot,
  } = useContext(CartContextV2);

  const addItem = (item: CartItem) =>
    dispatch({
      type: 'add_item',
      payload: item
    });

  const updateItem = (item: CartItem, index: number) =>
    dispatch({
      type: 'update_item',
      payload: { item, index }
    });

  const removeItem = (item: CartItem, index: number) =>
    dispatch({
      type: 'remove_item',
      payload: { item, index }
    });

  const removeItemsByProductIds = (productIds: number[]) =>
    dispatch({
      type: 'remove_items_by_product_ids',
      payload: productIds
    });

  const setPromoCode = (code: string) =>
    dispatch({
      type: 'set_promo_code',
      payload: code
    });

  const setPickupTime = (time: Date | null) =>
    dispatch({ type: 'set_pickup_time', payload: time });

  const setTip = async (newTip: Tip) =>
    dispatch({ type: 'set_tip', payload: newTip });

  const increaseItemQuantity = (
    item: CartItem,
    itemIndex: number,
    amount = 1
  ) =>
    dispatch({
      type: 'update_item',
      payload: {
        item: {
          ...item,
          quantity: item.quantity + amount
        },
        index: itemIndex
      }
    });

  const decreaseItemQuantity = (
    item: CartItem,
    itemIndex: number,
    amount = 1
  ) =>
    dispatch({
      type: 'update_item',
      payload: {
        item: {
          ...item,
          quantity: item.quantity - amount
        },
        index: itemIndex
      }
    });

  const getQuantity = () => {
    return items && items.length
      ? items.reduce((acc, cur) => acc + cur.quantity, 0)
      : 0;
  };

  const setHasRoundUp = (roundUp: boolean) =>
    dispatch({ type: 'set_round_up', payload: roundUp });

  const hasAlcohol = () =>
    !!items.find((current) =>
      current.attributes.find(
        (attribute) => attribute.name.toLowerCase() === 'alcohol'
      )
    );

  const getStatus = () => {
    return getCartStatus(lastModified);
  };

  const setLastModified = () => dispatch({ type: 'set_last_modified' });

  const clearCart = () => {
    dispatch({ type: 'clear' });
    window.localStorage.removeItem(shortId.toLowerCase());
  };

  const setPriceToDisplay = (pricingDisplay: PricingOptions) => {
    dispatch({ type: 'set_price_to_display', payload: pricingDisplay });
  };

  const setShowToggle = (shouldToggle: boolean) => {
    dispatch({ type: 'set_show_toggle', payload: shouldToggle });
  };

  const setIsBot = (botStatus: BotStatus) => {
    dispatch({ type: 'set_is_bot', payload: botStatus });
  };  

  return {
    items,
    hasAlcohol,
    addItem,
    updateItem,
    removeItem,
    removeItemsByProductIds,
    increaseItemQuantity,
    decreaseItemQuantity,
    promoCode,
    setPromoCode,
    pickupTime,
    setPickupTime,
    tip,
    setTip,
    hasRoundUp,
    setHasRoundUp,
    getQuantity,
    getStatus,
    lastModified,
    setLastModified,
    clearCart,
    priceToDisplay,
    setPriceToDisplay,
    showToggle,
    setShowToggle,
    isBot,
    setIsBot
  };
};
