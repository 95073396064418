import { backendConfig } from '../../../config/appConfig';
import axios from 'axios';

const fetchOrderId = async (storeId: string) => {
  const initializeUrl = `${backendConfig.apiBaseUrl}/eat/order/store/${storeId}/id`;

  const requestBody = JSON.stringify({
    gateway: 'FreedomPay',
    channel: 'mobile'
  });

  const axiosConfig = {
    method: 'POST',
    url: initializeUrl,
    headers: {
      'Content-Type': 'application/json',
      'x-api-key': backendConfig.apiKey
    },
    data: requestBody
  };
  try {
    const response = await axios(axiosConfig);
    return response.data.id;
  } catch (err) {
    throw err;
  }
};

export default fetchOrderId;
