import { Cart } from '../../../contexts/Cart/CartContextV2';
import { PricingOptions } from '../types';

export default function setPriceToDisplay(
  state: Cart,
  priceToDisplay: PricingOptions
) {
  return {
    ...state,
    priceToDisplay
  };
}
