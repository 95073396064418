/* eslint-disable @typescript-eslint/no-explicit-any */
import { double } from 'aws-sdk/clients/lightsail';
import { UserInfo } from './userInfo';

export interface FetchStatus {
  loading: boolean;
  error: string;
}

export interface DataResponse {
  [x: string]: any;
  data: any;
  status: any;
}

export interface ProcessOrderResponse extends Omit<DataResponse, 'data'> {
  data: {
    response: string;
    publicOrderId: string;
    eatTransactionId: string;
  };
}

export interface PostOrder {
  orderDate: string | null;
  hasRoundUp: boolean;
  tip: number;
  cart: {
    productId: number;
    clientItemId: string;
    quantity?: number;
    modifiers: number[];
    itemNotes: string;
  }[];
  customer: UserInfo;
  payment?: {
    amount: number;
    confirmationId: string;
    type: string;
    gateway: string;
  };
  discount?: string;
  menuId: number;
  channel: 'kiosk' | 'mobile';
  location?: string | null;
  orderType: 'dineIn' | 'takeOut';
  orderId: number | null;
}

export interface CalculateOrderObject {
  cart: {
    productId: number;
    quantity: number;
  }[];
  discount?: string | null;
  hasRoundUp: boolean;
  menuId: number;
}

export interface ColorConfig {
  brand: {
    50: string;
    100: string;
    200: string;
    300: string;
    400: string;
    500: string;
    600: string;
    700: string;
    800: string;
    900: string;
    primary: string;
  };
  font: {
    primary: string;
  };
  button: {
    primary: {
      font: string;
      background: string;
      outline: string;
    };
    secondary: {
      font: string;
      background: string;
      outline: string;
    };
  };
}

export interface SiteConfig {
  roundUpTitle: string;
  roundUpDescription: string;
  hasRoundUp: boolean;
  partnerId: string;
  partnerName: string;
  shortCode: string;
  currency: string;
  hasCapacity: boolean;
  hasDiscount: boolean;
  hasTip: boolean;
  warningFlags: string[];
  timeZone: string;
}

export interface StripeConfig {
  currency: string;
  country: string;
  accountId: string;
  publicKey: string;
}

export interface ApplePayConfig {
  merchantId: string;
  merchantDomain: string;
}

export enum PaymentGatewayType {
  Stripe = 'Stripe',
  CardPointe = 'Cardpointe',
  FreedomPay = 'FreedomPay'
}

export interface PaymentGateway {
  paymentGateway: PaymentGatewayType;
  currency: string;
  country: string;
  accountId: string;
  merchantId: string;
  publicKey: string;
  applePay?: ApplePayConfig;
}

export interface ConfigInterface {
  colorConfig: ColorConfig;
  partnerConfig: SiteConfig;
  locationConfig: {
    latitude: double;
    longitude: double;
  };
  paymentGateway: PaymentGateway;
}

export interface PhoneCountryResponse {
  name: string;
  countryCode: string;
}
