import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useCartV2 } from '../../hooks/useCartV2';
import { useEmptyCartRedirect } from '../../hooks/useEmptyCartRedirect';
import { styled } from '@mui/material/styles';
import Typography, { TypographyProps } from '@mui/material/Typography';
import OrderSummary from '../../components/OrderSummary';
import CartHeader from './Components/CartHeader/CartHeader';
import ConfirmationDialog from '../../components/ConfirmationDialog/ConfirmationDialog';
import PromoInput from './Components/DiscountInput/DiscountInput';
import OrderTotal from '../../components/OrderTotal/OrderTotal';
import ViewCheckoutButton from './Components/ViewCheckoutButton/ViewCheckoutButton';
import { StoreInterface } from '../../types/stores';
import { useMenu } from '../../hooks/useMenu';
import { useCalculate } from '../../hooks/useCalculate';
import ToggleOrderType from '../Menu/components/ToggleOrderType/ToggleOrderType';
import LoadingDialog from '../../components/LoadingDialog/LoadingDialog';
import { PricingOptions } from './types';
import { OrderType } from '../../types/order';

interface CartContentProps {
  store: StoreInterface;
  hasDiscount: boolean;
  shortId: string;
}
const StyledConfirmationButton = styled(Typography)<TypographyProps>(
  ({ theme }) => ({
    color: theme.palette.text.primary,
    fontSize: theme.spacing(3.5),
    marginRight: theme.spacing(3),
    fontWeight: 500,
    textTransform: 'uppercase'
  })
);

const StyledCartContainer = styled('section')(({ theme }) => ({
  paddingBottom: theme.spacing(25)
}));

const StyledOrderSummary = styled('div')<TypographyProps>(({}) => ({
  borderBottom: '1px solid #D0D5DD'
}));

const CartContent = ({ store, hasDiscount, shortId }: CartContentProps) => {
  const { t } = useTranslation();
  const { hasAlcohol, promoCode, tip, showToggle, priceToDisplay } =
    useCartV2();
  const { data } = useMenu(store ? store.id : '');
  const dismissAlcoholDisclaimer = sessionStorage.getItem('dismiss-alcohol');
  const {
    cartCalculations,
    errorDialog,
    dialogOpen,
    getTotals,
    isTotalsLoading
  } = useCalculate(store);

  const [openAlcoholDialog, setOpenAlcoholDialog] = useState(
    hasAlcohol() && !dismissAlcoholDisclaimer
  );

  useEmptyCartRedirect();

  useEffect(() => {
    getTotals();
  }, [priceToDisplay]);

  if (
    !dialogOpen &&
    (!cartCalculations ||
      !cartCalculations.data ||
      !cartCalculations.data.summary ||
      !cartCalculations.data.summary.total ||
      Number(cartCalculations.data.summary.total) <= 0)
  ) {
    return null;
  }

  const handleClose = () => {
    setOpenAlcoholDialog(false);
    sessionStorage.setItem('dismiss-alcohol', 'true');
  };

  return (
    <>
      <StyledCartContainer>
        <CartHeader store={store} />
        {showToggle && <ToggleOrderType />}
        {cartCalculations && (
          <StyledOrderSummary>
            <OrderSummary removable cartCalculations={cartCalculations} />
          </StyledOrderSummary>
        )}
        {hasDiscount && <PromoInput menuId={data?.menuId} store={store} />}
        {cartCalculations && (
          <OrderTotal
            promoCode={promoCode}
            showTip={false}
            tipType={tip.type}
            showRoundUp={false}
            cartCalculations={cartCalculations.data.summary}
            orderType={
              priceToDisplay === PricingOptions.TAKEOUT
                ? OrderType.takeOut
                : OrderType.dineIn
            }
          />
        )}
        <ViewCheckoutButton
          total={cartCalculations?.data.summary?.total}
          shortId={shortId}
        />
        {/* Alcohol Disclaimer Dialog */}
        <ConfirmationDialog
          open={openAlcoholDialog}
          handleClose={handleClose}
          title={t('restaurant.menu.alcoholDisclaimerTitle')}
          content={t('restaurant.menu.alcoholDisclaimer')}
        >
          <StyledConfirmationButton
            onClick={handleClose}
            role="button"
            data-testid="dismiss-alcohol-dialog-ok"
          >
            {t('restaurant.main.ok')}
          </StyledConfirmationButton>
        </ConfirmationDialog>

        {/* Updating Price Dialog */}
        <LoadingDialog open={isTotalsLoading} />
      </StyledCartContainer>
      {errorDialog}
    </>
  );
};

export default CartContent;
