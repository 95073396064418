import { backendConfig } from '../../../config/appConfig';
import axios from 'axios';
import { CalculateOrderObject } from '../../../types/fetch';

const fetchFPCalculate = async (
  storeId: string,
  payload: CalculateOrderObject
) => {
  const url = `${backendConfig.apiBaseUrl}/v5/eat/order/freedomPay/store/${storeId}`;
  const requestBody = JSON.stringify(payload);

  const axiosConfig = {
    method: 'POST',
    url: url,
    headers: {
      'Content-Type': 'application/json',
      'x-api-key': backendConfig.apiKey
    },
    data: requestBody
  };
  try {
    const response = await axios(axiosConfig);
    return response.data;
  } catch (err) {
    throw err;
  }
};

export default fetchFPCalculate;
