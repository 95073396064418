import {
  createContext,
  Dispatch,
  useEffect,
  useMemo,
  useReducer,
  ReactNode
} from 'react';
import addItem from '../../features/Cart/actions/addItem';
import removeItem from '../../features/Cart/actions/removeItem';
import updateItem from '../../features/Cart/actions/updateItem';
import setPromoCode from '../../features/Cart/actions/setPromoCode';
import setPickupTime from '../../features/Cart/actions/setPickupTime';
import { BotStatus, CartItem, PricingOptions, Tip } from '../../features/Cart/types';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import setHasRoundUp from '../../features/Cart/actions/setHasRoundUp';
import setTip from '../../features/Checkout/Functions/setTip';
import removeItemsByProductIds from '../../features/Cart/actions/removeItemsByProductIds';
import setPriceToDisplay from '../../features/Cart/actions/setPriceToDisplay';
import setShowToggle from '../../features/Cart/actions/setShowToggle';
import setIsBot from '../../features/Cart/actions/setIsBot';

export type Cart = {
  items: CartItem[];
  lastModified: Date;
  pickupTime: Date | string | null;
  tip: Tip;
  promoCode: string;
  hasRoundUp: boolean;
  dispatch: Dispatch<CartAction>;
  shortId: string;
  priceToDisplay: PricingOptions;
  showToggle: boolean;
  isBot: BotStatus;
};

type AddItemAction = {
  type: 'add_item';
  payload: CartItem;
};

type UpdateItemAction = {
  type: 'update_item';
  payload: { item: CartItem; index: number };
};

type RemoveItemAction = {
  type: 'remove_item';
  payload: { item: CartItem; index: number };
};

type RemoveItemsByProductIdsAction = {
  type: 'remove_items_by_product_ids';
  payload: number[];
};

type ClearCartAction = {
  type: 'clear';
};

type SetPickupTimeAction = {
  type: 'set_pickup_time';
  payload: Date | null | string;
};

type SetPromoCodeAction = {
  type: 'set_promo_code';
  payload: string;
};

type SetTipAction = {
  type: 'set_tip';
  payload: Tip;
};

type SetHasRoundUp = {
  type: 'set_round_up';
  payload: boolean;
};

type SetPriceToDisplay = {
  type: 'set_price_to_display';
  payload: PricingOptions;
};

type SetShowToggle = {
  type: 'set_show_toggle';
  payload: boolean;
};

type SetIsBot = {
  type: 'set_is_bot';
  payload: BotStatus;
};

type SetLastModified = {
  type: 'set_last_modified';
};

export type CartAction =
  | AddItemAction
  | UpdateItemAction
  | RemoveItemAction
  | RemoveItemsByProductIdsAction
  | SetPickupTimeAction
  | SetPromoCodeAction
  | SetTipAction
  | SetHasRoundUp
  | SetLastModified
  | ClearCartAction
  | SetPriceToDisplay
  | SetShowToggle
  | SetIsBot;

const initialCartState: Cart = {
  items: [],
  lastModified: new Date(),
  pickupTime: null,
  promoCode: '',
  tip: { type: '', value: 0 },
  hasRoundUp: false,
  shortId: '',
  dispatch: () => {},
  priceToDisplay: PricingOptions.DINEIN,
  showToggle: true,
  isBot: BotStatus.UNKNOWN
};

export const CartContextV2 = createContext<Cart>({ ...initialCartState });

export const cartReducer = (state: Cart, action: CartAction) => {
  switch (action.type) {
    case 'add_item': {
      const newState = addItem(state, action.payload);

      return {
        ...newState,
        lastModified: new Date()
      };
    }

    case 'update_item': {
      const newState = updateItem(
        state,
        action.payload.item,
        action.payload.index
      );

      return {
        ...newState,
        lastModified: new Date()
      };
    }

    case 'remove_item': {
      const newState = removeItem(state, action.payload.index);

      return {
        ...newState,
        lastModified: new Date()
      };
    }

    case 'remove_items_by_product_ids': {
      const newState = removeItemsByProductIds(state, action.payload);

      return {
        ...newState,
        lastModified: new Date()
      };
    }

    case 'set_promo_code': {
      const newState = setPromoCode(state, action.payload);

      return {
        ...newState,
        lastModified: new Date()
      };
    }

    case 'set_round_up': {
      const newState = setHasRoundUp(state, action.payload);

      return {
        ...newState,
        lastModified: new Date()
      };
    }

    case 'set_price_to_display': {
      const newState = setPriceToDisplay(state, action.payload);

      return {
        ...newState,
        lastModified: new Date()
      };
    }

    case 'set_show_toggle': {
      const newState = setShowToggle(state, action.payload);

      return {
        ...newState,
        lastModified: new Date()
      };
    }

    case 'set_is_bot': {
      const newState = setIsBot(state, action.payload);

      return {
        ...newState,
        lastModified: new Date()
      };
    }    

    case 'set_tip': {
      const newState = setTip(state, action.payload);

      return {
        ...newState,
        lastModified: new Date()
      };
    }

    case 'set_pickup_time': {
      const newState = setPickupTime(state, action.payload);

      return {
        ...newState,
        lastModified: new Date()
      };
    }

    case 'set_last_modified': {
      return {
        ...state,
        lastModified: new Date()
      };
    }

    case 'clear': {
      return {
        ...initialCartState,
        lastModified: new Date()
      };
    }

    default:
      throw new Error('Unknown reducer action');
  }
};

interface CartProviderV2Props {
  children: ReactNode;
  shortId: string;
}

export const CartProviderV2 = ({ children, shortId }: CartProviderV2Props) => {
  const [persistedCart, persistCart] = useLocalStorage(shortId.toLowerCase(), {
    ...initialCartState
  });
  const [state, dispatch] = useReducer(cartReducer, persistedCart);

  const cartContextValue = useMemo(
    () => ({
      ...state,
      shortId: shortId.toLowerCase(),
      dispatch
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state]
  );

  useEffect(() => {
    persistCart(state);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  return (
    <CartContextV2.Provider value={cartContextValue}>
      {children}
    </CartContextV2.Provider>
  );
};
