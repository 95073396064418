import ReCAPTCHA from 'react-google-recaptcha-enterprise';
import { BotStatus } from '../../features/Cart/types';
import { fetchBotStatus } from '../../services/orderService';
import { useCartV2 } from '../../hooks/useCartV2';
import { useEffect } from 'react';
import { appConfig } from '../../config/appConfig';
import { useAnalytics } from '../../hooks/useAnalytics';

interface RecaptchaProps {
  recaptchaRef: React.RefObject<ReCAPTCHA>;
  location: 'MENU' | 'CART' | 'CHECKOUT';
}

const Recaptcha = ({ recaptchaRef, location }: RecaptchaProps) => {
  const { setIsBot, isBot } = useCartV2();
  const logEvent = useAnalytics();

  useEffect(() => {
    if (isBot === BotStatus.UNKNOWN) {
      if (recaptchaRef && recaptchaRef.current) {
        recaptchaRef.current.executeAsync().then((token: string | null) => {
          if (token) {
            fetchBotStatus(token)
              .then((response) => {
                if (response.status === 200) {
                  setIsBot(BotStatus.IS_NOT_BOT);
                } else {
                  setIsBot(BotStatus.IS_BOT);

                  const analyticPayload = {
                    bot_attack: true,
                    location: location
                  };
                  logEvent('bot_attack', analyticPayload);
                }
              })
              .catch((error) => {
                // Default to not a bot
                console.log(error);
                setIsBot(BotStatus.IS_NOT_BOT);
              });
          }
        });
      }
    }
  }, [recaptchaRef]);

  return (
    <>
      <ReCAPTCHA
        data-testid="recaptcha-element"
        ref={recaptchaRef}
        sitekey={appConfig.reactRecaptchaSiteKey}
        size={'invisible'}
      />
    </>
  );
};

export default Recaptcha;
