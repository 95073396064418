import { Box, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { SessionKeys } from '../../../../utils/Session/Session';
import { useCartV2 } from '../../../../hooks/useCartV2';
import { PricingOptions } from '../../../Cart/types';

const StyledToggleSelectionContainer = styled(ToggleButtonGroup)<{
  value: string | null;
}>(({ theme }) => ({
  background: theme.colors.gray[200],
  display: 'flex',
  padding: theme.spacing(1),
  borderRadius: theme.spacing(10),
  position: 'relative',
  margin: `${theme.spacing(4)} auto`,
  width: '100%'
}));

const StyledToggleSelectionSlider = styled(Box)(({ theme }) => ({
  backgroundColor: theme.colors.base.white,
  boxShadow: '1px 1px 4px 0px rgba(0, 0, 0, 0.30)',
  borderRadius: theme.spacing(10),
  width: '50%',
  height: 'calc(100% - 8px)',
  border: 'none',
  position: 'absolute',
  left: theme.spacing(1),
  right: 'auto',
  transition: '.5s',

  '&[data-alignment-right="true"]': {
    left: 'calc(50% - 4px)',
    transition: '.5s',
    right: theme.spacing(1)
  }
}));

const StyledToggleSelectionButton = styled(ToggleButton)(({ theme }) => ({
  color: theme.colors.gray[500],
  backgroundColor: 'none',
  border: 'none',
  width: '100%',
  textTransform: 'none',
  borderRadius: theme.spacing(10),
  padding: theme.spacing(1.25),
  zIndex: '1',
  '&:hover': {
    background: 'none'
  },
  '&.Mui-selected': {
    fontWeight: '600',
    background: 'none',
    border: 'none'
  },
  '&.Mui-selected:hover': {
    background: 'none'
  }
}));

const ToggleOrderType = () => {
  const { t } = useTranslation();
  const { priceToDisplay, setPriceToDisplay } = useCartV2();

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newOrderType: PricingOptions
  ) => {
    if (newOrderType) {
      setPriceToDisplay(newOrderType);
      sessionStorage.setItem(SessionKeys.ORDER_TYPE, newOrderType);
    }
  };

  return (
    <StyledToggleSelectionContainer
      value={priceToDisplay}
      onChange={handleChange}
      exclusive
      data-testid="toggle-order-type-container"
    >
      <StyledToggleSelectionButton
        disableRipple
        value={PricingOptions.DINEIN}
        data-testid="dine-in-cta"
      >
        {t('restaurant.cart.dineIn')}
      </StyledToggleSelectionButton>
      <StyledToggleSelectionButton
        disableRipple
        value={PricingOptions.TAKEOUT}
        data-testid="take-away-cta"
      >
        {t('restaurant.cart.takeAway')}
      </StyledToggleSelectionButton>

      {/* Absolutely positioned slider for animation purposes */}
      <StyledToggleSelectionSlider
        data-alignment-right={priceToDisplay === PricingOptions.TAKEOUT}
      ></StyledToggleSelectionSlider>
    </StyledToggleSelectionContainer>
  );
};

export default ToggleOrderType;
