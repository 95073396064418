import GooglePayButton from '@google-pay/button-react';
import cardpointeTokenize, {
  CardPointeRequest
} from '../../../utils/Payments/CardPointe/cardpointeTokenize';
import { appConfig } from '../../../config/appConfig';
import cardpointePreauth from '../../../utils/Payments/CardPointe/cardpointePreauth';
import { useSiteConfig } from '../../../hooks/useSiteConfig';
import { GatewayError } from '../GatewayError';
import { PaymentMethodType } from '../types/GatewayResponse';

interface GooglePayProps {
  siteId: string;
  merchantId: string;
  merchantName: string;
  amount: number;
  onSuccess: (confirmationId: string) => void;
  onError: (error: GatewayError) => void;
}

const CardPointeGooglePayButton = (props: GooglePayProps) => {
  const { partnerConfig: siteConfig } = useSiteConfig();
  const handlePayment = async (
    paymentRequest: google.payments.api.PaymentData
  ) => {
    const data: CardPointeRequest = {
      encryptionHandler: 'EC_GOOGLE_PAY',
      deviceData: paymentRequest.paymentMethodData.tokenizationData.token,
      siteId: props.siteId
    };

    try {
      const cardPointeResponse = await cardpointeTokenize(data);

      const preauth = {
        cardNotPresent: true,
        siteId: siteConfig.partnerId,
        account: cardPointeResponse.token,
        amount: props.amount.toString(),
        name: paymentRequest.paymentMethodData.info?.billingAddress?.name ?? '',
        postal:
          paymentRequest.paymentMethodData.info?.billingAddress?.postalCode ??
          '',
        country:
          paymentRequest.paymentMethodData.info?.billingAddress?.countryCode ??
          ''
      };

      const cardPointePreauth = await cardpointePreauth(preauth);
      props.onSuccess(cardPointePreauth.retref);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      props.onError(
        new GatewayError(
          error.message,
          PaymentMethodType.GooglePay,
          null,
          null,
          undefined,
          error.code
        )
      );
    }
  };
  const allowedCardNetworks: google.payments.api.CardNetwork[] = [
    'AMEX',
    'DISCOVER',
    'INTERAC',
    'JCB',
    'MASTERCARD',
    'VISA'
  ];
  const environment =
    appConfig.googlePayEnv === 'PRODUCTION' ? 'PRODUCTION' : 'TEST';
  const merchantId = appConfig.googlePayMerchantId;

  return (
    <GooglePayButton
      environment={environment}
      buttonType="order"
      paymentRequest={{
        apiVersion: 2,
        apiVersionMinor: 0,
        allowedPaymentMethods: [
          {
            type: 'CARD',
            parameters: {
              allowedAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
              allowedCardNetworks: allowedCardNetworks,
              billingAddressParameters: {
                format: 'MIN'
              },
              billingAddressRequired: true
            },
            tokenizationSpecification: {
              type: 'PAYMENT_GATEWAY',
              parameters: {
                gateway: 'cardconnect',
                gatewayMerchantId: props.merchantId
              }
            }
          }
        ],
        merchantInfo: {
          merchantId: merchantId,
          merchantName: props.merchantName
        },
        transactionInfo: {
          totalPriceStatus: 'FINAL',
          totalPriceLabel: 'Total',
          totalPrice: props.amount.toString(),
          currencyCode: 'USD',
          countryCode: 'US',
          checkoutOption: 'COMPLETE_IMMEDIATE_PURCHASE'
        }
      }}
      onLoadPaymentData={handlePayment}
      onError={(error) => {}}
      buttonSizeMode="fill"
      style={{ width: '100%', height: '48px' }}
    />
  );
};

export default CardPointeGooglePayButton;
