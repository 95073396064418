import { Helmet } from 'react-helmet';
import { Routes } from './routing/Routes';
import { appConfig, firebaseConfig } from './config/appConfig';
import { useTheme, styled } from '@mui/material/styles';
import Button, { ButtonProps } from '@mui/material/Button';
import ConfirmationDialog from './components/ConfirmationDialog/ConfirmationDialog';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import ScrollRestore from './components/ScrollRestore/ScrollRestore';
import { useAnalytics } from './hooks/useAnalytics';
import { useSiteConfig } from './hooks/useSiteConfig';
import { SuccessToaster } from './components/Toast/SuccessToast';

const ConfirmationButton = styled(Button)<ButtonProps>(({ theme }) => ({
  color: theme.colors.partner[500]
}));

const App = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const logEvent = useAnalytics();
  const { partnerConfig: siteConfig } = useSiteConfig();
  const appName = appConfig.appName;

  useEffect(() => {
    logEvent('app_started');
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (siteConfig.partnerId) {
      logEvent('partner_selected');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [siteConfig.partnerId]);

  const fullAppName = firebaseConfig.projectId
    .split('-')
    .slice(0, 2)
    .map((word: string) => `${word[0].toUpperCase()}${word.slice(1)}`)
    .join(' ');

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (!window.navigator.onLine) {
      handleOpen();
    }
    window.addEventListener('offline', () => {
      handleOpen();
    });
  }, []);
  return (
    <>
      <Helmet>
        <meta name="theme-color" content={theme.colors.base.white} />
        <meta
          name="description"
          content={`${t('html.desc1')}${fullAppName}${t('html.desc2')}`}
        />
        <title>{appName}</title>
      </Helmet>
      <ScrollRestore />
      <SuccessToaster />
      <Routes />
      <ConfirmationDialog
        open={open}
        handleClose={handleClose}
        title={t('error.noInternet.title')}
        content={t('error.noInternet.description')}
      >
        <ConfirmationButton onClick={handleClose}>
          {t('restaurant.main.ok')}
        </ConfirmationButton>
      </ConfirmationDialog>
    </>
  );
};

export default App;
